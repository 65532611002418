<template>
  <div class="gallery-disclaimer traitranks-apes-disclaimer">
    <img src="@/assets/img/info.png">
    <div>
      <div
        class="gallery-disclaimer-title"
      >
        Ranking Disclaimer - Ranks are updated hourly. Rarity ranking for Apes and Ape Traits is subject to change.
      </div>
      <div class="gallery-disclaimer-text">
        See tooltip here for more ranking details.
        <VDropdown
          theme="ape-disclaimer"
          placement="bottom"
          :distance="24"
          :triggers="['click', 'hover']"
          class="test"
        >
          <!-- This will be the popover reference (for the events and position) -->
          <div class="traitranks-apes-disclaimer-trigger">
            <IInfo width="14" />
          </div>
          <!-- This will be the content of the popover -->
          <template #popper>
            <div class="traitranks-apes-disclaimer-tooltip">
              <ul>
                <li>1. Rankings are dependent on both the Traits in circulation and the Max Quantity available in Loot Boxes less the number of Traits which are burned.</li>
                <li>2. This is an alpha version of TraitRanks for Apes and Apes Traits.  Due to the dynamic range of trait counts and the ongoing introduction of new traits, we are likely to tweak and optimize the factors and inputs to the formula.</li>
                <li>3. Certain categories have a stronger weighting compared to other categories.  This will result in varying rankings when comparing traits with similar counts across categories.</li>
                <li>4. There are scenarios where traits within the same category can have different factors and have different rankings despite having similar counts.  As an example there are many variations of t-shirts and those have been factored accordingly.</li>
              </ul>
            </div>
          </template>
        </VDropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue"
import IInfo from "@/assets/img/info.svg?inline"
export default defineComponent({

  components: { IInfo, },
  setup() {
    const tooltip = computed(() => {
      return {
        content: `1. Rankings are dependent on both the Traits in circulation, the max quantity available in loot boxes (and the number of Traits which are burned) 
2. This is an alpha version of TraitRanks. Because of the dynamic range of our Trait counts, we are likely to tweak and optimize our rankings formula a bit for the first month of the release.
3. Some categories are more important than categories - so two traits with the same number of traits in circulation across different categories may have different rarity scores.
4. Some Traits within the category or buffed (negatively or positively) with respect to Traits in the same category (Example T Shirts are negatively buffed). So two Traits with the same number of Traits in circulation within the same categories may have different rarity scores.`,
        html: true,
      }
    })
    return { tooltip }
  }
})
</script>
<style lang="scss">
.traitranks-apes-disclaimer {
  .gallery-disclaimer-text > div {
    display: inline-block;
  }
  &-trigger {
    display: inline-block;
  }
  &-tooltip {
    max-width: 500px;
    li {
      margin: 5px 0;
    }
    ul {
      padding: 0;
      list-style-type: none;
    }
  }
}
.v-popper--theme-ape-disclaimer .v-popper__arrow-outer {
  border-color: rgba(255, 255, 255, 0.3);
  ;
  
}
.v-popper--theme-ape-disclaimer .v-popper__inner {
  font-size: 14px;
  background-color: var(--gray-light);
  border-radius: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
</style>