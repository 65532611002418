<template>
  <div class="gallery-pagination-select-wrapper">
    <span class="gallery-pagination-select-title">Per page:</span>
    <div
      v-click-outside="{ handler: close }"
      class="gallery-pagination-select"
      :class="{ open: isOpen }"
    >
      <div
        class="gallery-pagination-select-header"
        @click="toggle"
      >
        <span>
          {{ activeItem.name }}
        </span>
        <IArrowDown
          width="12"
          class="gallery-pagination-select-arrow"
        />
      </div>
      <transition>
        <div
          v-if="isOpen"
          class="app-select-body gallery-pagination-select-body"
        >
          <a
            v-for="item in items"
            :key="item.value"
            href="javascript:void(0)"
            class="app-select-item gallery-pagination-select-item"
            @click.prevent="selectItem(item.value)"
          >
            {{ item.name }}
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import IArrowDown from "../../assets/icons/arrow-down.svg?inline";
export default {
  components: { IArrowDown },
  props: {
    value: Number,
  },
  data() {
    return {
      isOpen: false,
      items: [
        {
          name: "48",
          value: 48,
        },
        {
          name: "100",
          value: 100,
        },
        {
          name: "250",
          value: 250,
        },
      ],
    };
  },
  computed: {
    activeItem() {
      return this.items.find((item) => item.value === this.value) || {};
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    selectItem(value) {
      this.$emit("update:value", value);
      this.close();
    },
  },
};
</script>
