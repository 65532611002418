<template>
  <div class="filter-switcher-wrapper">
    <div class="filter-switcher-title">
      {{ title }} <span v-if="isNew">New</span>
    </div>
    <ASwitcher
      :value="value"
      @update:value="$emit('toggle', $event)"
    />
  </div>
</template>
<script>
import ASwitcher from "../ASwitcher.vue";
export default {
  components: { ASwitcher },
  props: {
    value: Boolean,
    title: String,
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    toggle() {
      this.$emit("toggle", !this.value);
    },
  },
};
</script>
