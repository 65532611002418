<template>
    <div class="traitranks-offer">
        <div class="traitranks-offer-price">
            {{ order.price }}
        </div>
        <div class="traitranks-offer-amount">
            {{ order.amount }}
        </div>
        <div class="traitranks-offer-item">
            #{{ itemId }}
        </div>
        <div class="traitranks-offer-action">
            <a href="#" @click.prevent="cancelOrder">Cancel</a>
        </div>
       
    </div>
</template>
<script setup>
import ATable from '@/components/ATable/ATable.vue';
import { computed } from 'vue';
const props = defineProps({ order: Object })
const isUtility = computed(() => props.order.isUtility)
const itemId = computed(() => props.order.trait_id)
const cancelOrder = () => {}

</script>
<style lang="scss">
.traitranks-offer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    &-price {
        font-weight: bold;
    }
    &-amount {}
    &-item {

    }
}
</style>