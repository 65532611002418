<template>
  <div class="gallery-card trait-ranks-utility-card" @click="buyTrait">
    <div class="gallery-card-image">
      <img :src="image" :alt="token.id" width="467" height="467">
    </div>

    <div class="gallery-card-content">
      <div class="gallery-card-info">
        <span class="gallery-card-token">#{{ token.id }}</span>
        <div class="traitranks-utility-card-token-actions">
          <div class="traitranks-utility-card-token-action" v-if="sellPrice">
            <span>Buy for</span>
            {{ sellPrice }} METH
          </div>
          <div class="traitranks-utility-card-token-action" v-if="buyPrice">
            <span>Sell for</span> {{ buyPrice }} METH
          </div>
        </div>
        <span class="gallery-card-guild" v-if="token.guild">{{ token.guild }}</span>

      </div>


      <a :href="openSeaLink" target="_blank" class="gallery-card-opensea" @click.stop>
        <span>Go to OpenSea</span>
        <IOpenSea width="25" />
      </a>

    </div>
    <!-- <span class="gallery-card-burned" v-if="token.isBurned"
      ><img src="../../assets/icons/flame.svg"
    /></span>-->

  </div>
</template>

<script>

import IOpenSea from "@/assets/icons/opensea.svg?inline";
import IEthereum from "@/assets/icons/ethereum.svg?inline";
import { Config } from "@/config";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { computed } from "vue";
export default {
  components: { IOpenSea, IEthereum, },
  props: {
    totalItems: Number,
    token: {
      type: Object,
    },
    meth: {
      type: Number,
    },

  },
  data() {
    return {

    };
  },
  setup(props, { emit }) {

    const sellOrders = computed(() => [...props.token.sell_orders].sort((a, b) => a.price - b.price))
    const buyOrders = computed(() => [...props.token.buy_orders])

    const sellPrice = computed(() => sellOrders.value[0]?.price)
    const buyPrice = computed(() => buyOrders.value[0]?.price)
    const buyTrait = () => {


      useModal().open({
        name: ModalName.BuyTraitModal, props: {
          sellOrders: sellOrders.value,
          buyOrders: buyOrders.value,
          trait: props.token,
          isUtility: true,
          onSuccess: () => {
            emit('refresh')
          }
        }
      })
    }
    return { buyTrait, sellPrice, buyPrice }
  },
  computed: {

    openSeaLink() {
      return Config.OSUrl.utilities + this.token.id;
    },
    image() {
      return this.token.image;
    }
  }
};
</script>

<style lang="scss">
.traitranks-utility-card-token {
  &-actions {}

  &-action {
    span {
      opacity: 0.5;
    }

    font-size: 14px;
    
  }
}
</style>