<template>
  <div
    class="gallery-card"
    :class="{
      'for-sale': token.forSale, burned: token.isBurned,
      'top-1': isTop1,
      'top-10': isTop10,
      'top-25': isTop25,
    }"
  >
    <div class="gallery-card-image">
      <img
        :src="image"
        :alt="token.id"
        width="467"
        height="467"
      >
    </div>

    <div class="gallery-card-content">
      <div class="gallery-card-info">
        <span class="gallery-card-rarity">{{ token.isGod ? "God Rank" : "Rank" }} {{ token.rank }}</span>
        <!--<span>{{token.score}}</span>-->
        <span class="gallery-card-token">#{{ token.id }}</span>
        <!-- <span>{{ token.score }}</span> -->
      </div>
      <div
        v-if="token.isGod"
        class="gallery-card-power"
      >
        <GodBullPowerProgress
          :progress="token.godBullPower"
          :show-tooltip="false"
        />
      </div>
      <div
        v-if="!token.isTeen"
        class="gallery-card-meth"
      >
        <img
          src="../../assets/img/meth.png"
          class="gallery-card-meth-image"
        >
        <div class="gallery-card-meth-tooltip">
          METHANE TOKENS AVAILABLE
        </div>
        {{ token.meth != null ? token.meth : "-" }} METH
      </div>
      <a
        v-if="owner && showOwner"
        class="gallery-card-owner"
      >
        <IUser width="12" />
        <a
          :href="
            'https://opensea.io/' + (token.ownerUsername || token.ownerAddress)
          "
          target="_blank"
          @click.stop
        >{{ owner }}</a>
      </a>

      <a
        v-if="showOSButton"
        :href="openSeaLink"
        target="_blank"
        class="gallery-card-opensea"
        @click.stop
      >
        <span>{{ token.forSale ? "Buy on OpenSea" : "Go to OpenSea" }}</span>
        <IOpenSea width="25" />
      </a>

      <!-- <span class="guild">{{ token.guild }}</span> -->
    </div>
    <!-- <span class="gallery-card-burned" v-if="token.isBurned"
      ><img src="../../assets/icons/flame.svg"
    /></span>-->

    <div
      v-if="token.price"
      class="gallery-card-price"
    >
      <IEthereum width="12" />
      {{ token.price }}
    </div>
  </div>
</template>

<script>
let flip = false;
import IOpenSea from "../../assets/icons/opensea.svg?inline";
import IEthereum from "../../assets/icons/ethereum.svg?inline";
import { shorten } from "../../helpers/utils";
import IUser from "../../assets/icons/user.svg?inline";
import GodBullPowerProgress from "../Collection/GodBullPowerProgress.vue";
import { Config } from "@/config";
// import { Config } from "../../config";
// import { getTeenImageStaging } from "../../utils";
export default {
  components: { IOpenSea, IEthereum, IUser, GodBullPowerProgress },
  props: {
    totalItems: Number,
    token: {
      type: Object,
    },
    meth: {
      type: Number,
    },
    showOSButton: {
      type: Boolean,
      default: true,
    },
    showOwner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      flip,
    };
  },
  computed: {
    owner() {
      return (
        this.token.ownerUsername ||
        (this.token.ownerAddress && shorten(this.token.ownerAddress, 4, 4))
      );
    },
    openSeaLink() {
      return this.token.isTeen
        ? Config.OSUrl.teens + this.token.id
        : Config.OSUrl.genesis + this.token.id;
    },
    image() {
      return this.token.image;
    },
    isInTop() {
      return (this.token.rank / this.totalItems) * 100 <= 25
    },
    isTop1() {
      return (this.token.rank / this.totalItems) * 100 <= 1
    },
    isTop10() {
      return (this.token.rank / this.totalItems) * 100 <= 10
    },
    isTop25() {
      return (this.token.rank / this.totalItems) * 100 <= 25
    },


    // image() {
    //   return this.token.isBurned && Config.network.name === "sepolia"
    //     ? getTeenImageStaging(this.token.id)
    //     : this.token.image;
    // },
  },
  async created() { },
  methods: {
    toggleFlip: function () {
      this.flip = !this.flip;
    },
  },
};
</script>
