<template>
  <TraitRanksFilters>
    <GalleryFiltersSearch @search="search" />
    <div
      v-if="selectedTab === 'teens' && subType === 'teens' || selectedTab === 'bulls'"
      class="filter-block"
    >
      <FilterSwitcher
        title="Buy now"
        :value="buyNowActive"
        @toggle="$emit('buyNow', $event)"
      />
    </div>

    <GallerySliderBlock title="RANK">
      <GalleryFilterRank
        v-if="maxRank"
        class="filter-offset"
        :value="activeRanks"
        :max="maxRank"
        @update:value="$emit('rankUpdate', $event)"
      />
    </GallerySliderBlock>
    <GallerySliderBlock
      v-if="selectedTab === 'teens' && subType === 'teens' || selectedTab === 'bulls'"
      title="PRICE"
    >
      <GalleryFilterSliderInput
        v-if="maxPrice"
        sufix="ETH"
        class="filter-offset"
        :value="activePrices"
        :max="maxPrice"
        :min="minPrice"
        @update:value="$emit('priceUpdate', $event)"
      />
    </GallerySliderBlock>
    <GallerySliderBlock
      v-if="selectedTab !== 'teens'"
      title="METH"
    >
      <GalleryFilterSliderInput
        v-if="maxMeth"
        sufix="METH"
        class="filter-offset"
        :value="activeMeth"
        :max="maxMeth"
        :min="minMeth"
        :use-decimal="false"
        :use-scale="false"
        @update:value="$emit('methUpdate', $event)"
      />
    </GallerySliderBlock>

    <div class="filter-block filter-block-attributes">
      <div class="filter-block-title">
        Attributes
        <button
          class="filter-block-attributes-expand"
          :class="{ expanded: isExpandedAll }"
          @click="expandAll"
        >
          <span>{{ isExpandedAll ? "Collapse all" : "Expand all" }}</span>
          <IDoubleArrowDown />
        </button>
      </div>
      <div
        v-if="selectedTab === 'teens' && subType === 'teens' || selectedTab === 'bulls'"
        class="filter-block-attributes-gap"
      >
        <div class="filter-block-attributes-gap-title">
          <div class="filter-block-attributes-gap-title-inner">
            Floor Gap
            <span class="filter-block-attributes-gap-title-new">NEW</span>
            <IInfo width="12" />
          </div>
          <div class="filter-block-attributes-gap-tooltip">
            Floor gap is the difference in ETH between current floor bull price
            and the next lowest for each trait
          </div>
        </div>
        <ASwitcher v-model:value="activeDealPrice" />
      </div>
      <FilterItem
        v-for="attribute in attributes"
        :key="attribute.name"
        v-model:isOpen="filterOpen[attribute.name]"
        :filter="attribute"
        :selected-traits="selectedTraits"
        :active-deal-price="activeDealPrice"
        @update:value="$emit('update:attributes', $event)"
      />
    </div>
  </TraitRanksFilters>
</template>
<script>
import FilterItem from "../FilterItem.vue";
import FilterSwitcher from "../FilterSwitcher.vue";
import GalleryFilterRank from "../GalleryFilterRank.vue";
import GalleryFilterSliderInput from "../GalleryFilterSliderInput.vue";
import GallerySliderBlock from "../GallerySliderBlock.vue";
import GalleryFiltersSearch from "../GalleryFiltersSearch.vue";
import IDoubleArrowDown from "@/assets/icons/double-arrow-down.svg?inline";
import IClose from "@/assets/icons/close.svg?inline";
import IFilter from "@/assets/icons/filter.svg?inline";
import IInfo from "@/assets/img/info.svg?inline";
import ASwitcher from "@/components/ASwitcher.vue";
import TraitRanksFilters from "./TraitRanksFilters.vue";
export default {
    components: {
        TraitRanksFilters,
        IFilter,
        GallerySliderBlock,
        FilterItem,
        FilterSwitcher,
        GalleryFilterRank,
        IClose,
        GalleryFilterSliderInput,
        IDoubleArrowDown,
        ASwitcher,
        IInfo,
        GalleryFiltersSearch,
    },
    props: {
        maxRank: Number,
        burnedActive: Boolean,
        selectedTab: String,
        subType: String,
        activeMeth: Array,
        activeRanks: Array,
        activePrices: Array,
        buyNowActive: Boolean,
        showMeth: Boolean,
        selectedTraits: Array,
        filters: {
            type: Object,
        },
        toggleFilters: {
            type: Function,
        },
        total: Number,
        maxPrice: Number,
        minPrice: Number,
        maxMeth: Number,
        minMeth: Number,
    },
    emits: [
        "buyNow",
        "rankUpdate",
        "priceUpdate",
        "methUpdate",
        "search",
        "update:burnedActive",
        'update:attributes'
    ],
    data() {
        return {
            activeDealPrice: true,
            filterOpen: {},
        };
    },
    computed: {
        isExpandedAll() {
            return Object.keys(this.filters).filter((key) => {
                return this.filterOpen[key];
            }).length;
        },
        attributes() {
            const attributeNames = Object.values(this.filters);
            if (!this.activeDealPrice) return attributeNames;
            const sortedAttributes = Object.keys(this.filters).map((attrName) => {
                const { name, traits } = this.filters[attrName];
                const sortedTraits = [...traits].sort(
                    (a, b) => (b.gap || 0) - (a.gap || 0)
                );
                return { name, traits: sortedTraits };
            });
            sortedAttributes.sort(
                (a, b) => (b.traits[0].gap || 0) - (a.traits[0].gap || 0)
            );
            return sortedAttributes;
        },
    },
    methods: {
        expandAll() {
            const isExpandedAll = this.isExpandedAll;
            Object.keys(this.filters).map((key) => {
                if (isExpandedAll) {
                    this.filterOpen[key] = false;
                } else {
                    this.filterOpen[key] = true;
                }
            });
        },
        search: function (payload) {
            this.$emit("search", payload);
        },
    },
};
</script>
