<template>
    <div class="traitranks-apes-traits">
        <TraitRanksFiltersApes
            :class="{ active: !hideFilters && !isLoading }"
            :gender="activeGender"
            :filters="categoriesData"
            :selected-traits="selectedTraits"
            :active-ranks="activeRanks"
            :max-rank="maxRank"
            @search="onSearch"
            @toggle="toggleFilters"
            @update:attributes="updateAttributes"
            @rankUpdate="rankUpdate"
        />
        <div class="traitranks-page-gallery" :class="'gallery-layout-' + activeLayout">
            <GalleryTopBar :value="selectedTab" @update:value="selectTab" />
            <div class="gallery-banner">
                <img :src="galleryBanner" alt="banner" />
                <AToggler
                    :tabs="[
                        { name: 'Apes', value: 'Ape' },
                        { name: 'God Apes', value: 'God Ape' },
                    ]"
                    :value="subType"
                    @update:value="subTypeUpdate"
                />
            </div>

            <div class="gallery-items-header">
                <div class="gallery-items-panel">
                    <!-- <GallerySort
                        v-model:value="sort"
                        :show-god-power="selectedTab === 'bulls' && subType === 'gods'"
                        :show-meth="selectedTab !== 'teens'"
                        :show-price="subType !== 'burned_teens'"
                        @update:value="sortUpdate"
                    />-->
                    <ASelect v-model:value="sort" :items="sortOptins" style="min-width: 260px;" />

                    <GalleryLayoutSwiticher v-model:value="activeLayout" />
                    <div class="gallery-items-panel-filters-mob" @click="toggleFilters">
                        <IFilter width="16" />Filters
                    </div>
                </div>
                <TraitRanksFiltersItems
                    v-if="hasActiveFilters"
                    :items="selectedTraits"
                    @clear="clearFilters"
                    @select="updateAttributes"
                />
                <TraitRanksApesDisclaimer v-if="subType === 'Ape'" />
                <div class="gallery-disclaimer" v-else>
                    <img src="@/assets/img/info.png" />
                    <div>
                        <div
                            class="gallery-disclaimer-title"
                        >This is a beta version, the final God Ape ranks will vary significantly as new God Apes are still being minted.</div>
                    </div>
                </div>
            </div>
            <div v-if="isLoading" class="gallery-loading-wrapper">
                <img class="loader" src="@/assets/img/spinner-clear.svg" width="150" />
            </div>
            <div v-else class="gallery-tokens-wrapper">
                <div class="gallery-items">
                    <div v-for="token in tokens" :key="token.id" class="gallery-item">
                        <!-- <GalleryTokenCard
                            :token="token"
                            :use-menu="false"
                            :show-o-s-button="subType !== 'burned_teens'"
                            :show-owner="subType !== 'burned_teens'"
                            :total-items="total"
                            @click="openModal(token)"
                        />-->
                        <TraitRanksApesCard
                            :totalItems="total"
                            :item="token"
                            :token="token"
                            @click.prevent="openModal(token)"
                        />
                    </div>
                </div>
            </div>

            <GalleryPagination
                v-model:page="page"
                v-model:pageSize="perPage"
                :total-items="total"
                :total-filtered-items="perPage"
                :items-name="'Apes'"
                @update:page="updatePage"
            />
        </div>
        <transition name="t-gallery-modal">
            <GalleryModal v-if="showModal" :token="modalToken" @close="closeModal" />
        </transition>
    </div>
</template>
  
<script>
import GalleryFilters from "@/components/TraitRanks/GalleryFilters.vue";
import GalleryTokenCard from "@/components/TraitRanks/GalleryTokenCard.vue";
import GalleryModal from "@/components/TraitRanks/GalleryModal.vue";
import GalleryPagination from "@/components/TraitRanks/GalleryPagination.vue";
import GallerySort from "@/components/TraitRanks/GallerySort.vue";
import AToggler from "@/components/AToggler.vue";

import IFilter from "@/assets/icons/filter.svg?inline";
import GalleryLayoutSwiticher from "@/components/TraitRanks/GalleryLayoutSwitcher.vue";
import GalleryTopBar from "@/components/TraitRanks/GalleryTopBar.vue"
import TraitRanksMethDisclaimer from "@/components/TraitRanks/TraitRanksMethDisclaimer.vue";
import TraitRanksUnderworldDisclaimer from "@/components/TraitRanks/TraitRanksUnderworldDisclaimer.vue";
import TraitRanksFiltersItems from "@/components/TraitRanks/TraitRanksFiltersItems.vue";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { captureError } from "@/helpers/captureError";
import { useToast } from "@/composables/useToast";
import ASelect from "@/components/ASelect.vue";
import { useBapApi } from "@/api/useBapApi";
import TraitRanksFiltersApes from "../TraitRanksFilters/TraitRanksFiltersApes.vue";
import TraitRanksApesCard from "../TraitRanksApes/TraitRanksApesCard.vue";
import { useStorageApi } from "@/api/useStorageApi";
import TraitRanksApesDisclaimer from "../TraitRanksApes/TraitRanksApesDisclaimer.vue";
import { mapWritableState } from "pinia";


export default {
    components: {
        ASelect,
        TraitRanksApesDisclaimer,
        TraitRanksApesCard,
        TraitRanksFiltersApes,
        TraitRanksFiltersItems,
        TraitRanksUnderworldDisclaimer,
        TraitRanksMethDisclaimer,
        GalleryTopBar,
        IFilter,
        GalleryLayoutSwiticher,
        AToggler,
        GalleryFilters,
        GalleryTokenCard,
        GalleryModal,
        GalleryPagination,
        GallerySort,
    },
    data() {
        return {
            hideFilters: true,
            activeLayout: "big",
            showModal: false,
            'maxPrice': 0,
            'total': 0,
            subType: 'Ape',
            'tokens': [],
            selectedTraits: [],
            activePrices: [],
            'buyNowActive': false,
            'searchWallet': '',
            'searchIds': [],
            'isLoading': false,
            activeRanks: [],
            'page': 1,
            maxRank: 10000,
            // id, total_minted
            sortName: "rank",
            sortOrder: "asc",
            perPage: 48,
            selectedTab: 'apes',
            activeGender: 0,
            activeCategories: [],
            boxType: undefined,
            modalToken: {},
            traitsMap: {},
        };
    },
    created() {
        this.init()
    },
    computed: {
        categoriesData() {
            const filters = [];
            const traits = this.traitsMap
            traits &&
                Object.keys(traits).forEach((category) => {
                    let filter = traits[category];
                    if (!filters[category]) {
                        filters[category] = {
                            name: category,
                            traits: [],
                        };
                    }
                    const selectedTraits = [];
                    Object.keys(filter).forEach((trait) => {
                        const floorPrice = filter[trait].floor_price;
                        const dealPrice = [...(filter[trait].prices || [])].sort(
                            (a, b) => a - b
                        )[1];
                        const gap = dealPrice - floorPrice;
                        selectedTraits.push({
                            name: trait,
                            creativityFactor: 1,
                            total: (filter[trait] && filter[trait].assets && filter[trait].assets.length) || 1,
                            floorPrice,
                            dealPrice,
                            gap,
                            forSaleCount: filter[trait].for_sale_count,
                            selected: false,
                        });
                    });
                    selectedTraits.sort((a, b) => {
                        return b.total - a.total;
                    });

                    filters[category].traits = selectedTraits;
                });

            filters.sort();
            return filters;
        },

        sort: {
            get() {
                return `${this.sortName}_${this.sortOrder}`
            },
            set(val) {
                const [name, order] = val.split('_')
                this.sortName = name
                this.sortOrder = order
                this.page = 1
                this.refreshTokens()
            }
        },

        sortOptins() {
            return [
                {
                    name: "Id: Low to High",
                    value: 'id_asc'
                },
                {
                    name: "Id: High to Low",
                    value: 'id_desc'
                },
                {
                    name: "Rank: Top to Bottom",
                    value: 'rank_asc'
                },
                {
                    name: "Rank: Bottom to Top",
                    value: 'rank_desc'
                },
                // {
                //     name: "Last Customized",
                //     value: 'tokenId_desc'
                // },
                // {
                //     name: "Token Id: Bottom to Bottom",
                //     value: 'tokenId_asc'
                // },

            ]
        },

        galleryBanner() {
            const banner = require("@/assets/img/banners/1.3.jpg");

            return banner;
        },

        hasActiveFilters() {
            return (
                this.selectedTraits.length > 0 ||
                this.activePrices.length ||
                this.buyNowActive
            );
        },

    },
    methods: {
        subTypeUpdate(newValue) {
            this.subType = newValue
            this.fetchItems()
            this.fetchCategories()
        },
        async fetchItems(silent = false) {
            if (!silent) {
                this.isLoading = true;
            }
            try {
                const skip = (this.page - 1) * this.perPage;
                const data = await useBapApi({
                }).exec({
                    url: "/collection/list",
                    params: {
                        type: this.subType,
                        sort: this.sortName === 'tokenId' ? 'token_id' : this.sortName,
                        order: this.sortOrder === "asc" ? undefined : this.sortOrder,
                        limit: this.perPage,
                        skip: skip === 0 ? undefined : skip,
                        minrank: this.activeRanks[0],
                        maxrank: this.activeRanks[1],
                        traits: this.selectedTraits,
                        id: this.searchIds,
                    },
                    paramsSerializer: (params) => {
                        let str = "";
                        Object.keys(params).forEach((key, idx) => {
                            if (!params[key]) return;

                            if (key === "traits") {
                                if (params[key].length) {
                                    str += "&";
                                }
                                params[key].forEach((item, index) => {
                                    const trait = item.name;
                                    const value = item.trait;
                                    str += `trait=${trait}&value=${value}`;
                                    if (index < params[key].length - 1) {
                                        str += "&";
                                    }
                                });
                                return;
                            }
                            if (Array.isArray(params[key])) {
                                if (params[key].length) {
                                    str += "&";
                                }
                                params[key].forEach((item, index) => {
                                    str += `${key}=${item}`;
                                    if (index < params[key].length - 1) {
                                        str += "&";
                                    }
                                });
                                return;
                            }
                            str += `&${key}=${params[key]}`;
                        });

                        return str.substr(1);
                    },
                })

                if (!data || !data.success) {
                    throw new Error("Error while fetching apes traits", { cause: data });
                }
                const { count, result: tokens } = data.result;

                this.total = count;
                this.maxRank = count
                this.tokens = tokens;
            } catch (err) {
                useToast().error({ title: "Error while loading items" });
                captureError(err);
            }
            this.isLoading = false;
        },
        async fetchCategories() {
            try {
                const data = await useApesStorageApi({ throw: true, toast: { error: true } }).exec({
                    url: "/metadata/traits-map",
                    params: {
                        type: this.subType
                    }
                })
                console.log(data)
                this.traitsMap = data.traitsMap
            } catch (err) {
                captureError(err)
            }
        },
        async init() {
            this.isLoading = true;
            try {
                await Promise.all([
                    // this.loadTraitsMap(),
                    // this.loadStats(),
                    this.fetchItems(),
                    this.fetchCategories()
                ]);
            } catch (err) {
                captureError(err);
            }

            this.isLoading = false;
        },
        rankUpdate(value) {
            this.page = 1
            this.activeRanks = value;
            this.refreshTokens();
        },
        updateAttributes({ name, trait, active }) {
            if (active) {
                this.selectedTraits.push({ name, trait })
            } else {
                const idx = this.selectedTraits.findIndex(item => (item.trait === trait && item.name === name))
                this.selectedTraits.splice(idx, 1)
            }
            this.page = 1
            this.refreshTokens()
        },
        onUpdateGender(value) {

            let newGender = 0
            if (value.trait === 'Male') {
                newGender = 1
            } else if (value.trait === 'Female') {
                newGender = 2
            } else if (value.trait === 'Unisex') {
                newGender = 3
            } else if (value.trait === "All") {
                newGender = 0
            }

            this.activeGender = newGender
            this.page = 1
            this.refreshTokens()
        },
        onUpdateCategoreis({ active, trait }) {
            if (active) {
                this.activeCategories.push({ name: trait })
            } else {
                const idx = this.activeCategories.findIndex(item => item.name === trait)
                this.activeCategories.splice(idx, 1)
            }
            this.page = 1
            this.refreshTokens()
        },

        clearAttributes() {
            this.selectedTraits = []
            this.page = 1

        },
        clearFilters() {
            this.clearAttributes()
            this.activePrices = []

            this.buyNowActive = false
            this.page = 1

            this.refreshTokens();
        },

        sortUpdate() {
            this.page = 1
            this.fetchItems()
        },
        priceUpdate(newPrices) {
            this.page = 1

            this.activePrices = newPrices;
            if (newPrices[0] > this.minPrice || newPrices[1] < this.maxPrice) {
                this.buyNowActive = true;
            } else {
                this.buyNowActive = false;
            }
            this.refreshTokens();
        },
        methUpdate(newMeth) {
            this.page = 1
            this.activeMeth = newMeth;
            this.refreshTokens();
        },

        onSearch({ type, value }) {
            // if (type === "id") {
            this.searchIds = value
                .split(/[,\s;]/)
                .map((item) => {
                    return parseInt(item);
                })
                .filter((item) => !isNaN(item));
            // this.searchWallet = ''
            // } else if (type === "wallet") {
            //     this.searchWallet = value
            //     this.searchIds = [];
            // }
            this.page = 1

            this.refreshTokens();
        },
        async selectTab(tab) {
            // this.selectedTab = tab;
            this.$emit('update:type', tab)
            return

        },

        async refreshTokens() {
            await this.fetchItems()
            window.scrollTo(0, 0,)
        },



        toggleFilters() {
            this.hideFilters = !this.hideFilters;
        },

        buyNowToggle(value) {

            this.buyNowActive = value;
            if (!value) {
                this.activePrices = [];
            }
            this.refreshTokens();
        },
        openModal(token) {
            this.modalToken = { ...token, traits: token.attributes }
            this.showModal = true;
        },
        closeModal(token) {
            this.showModal = false;
            this.modalToken = {};

        },

        updatePage() {
            this.refreshTokens()
        },
    },

};
</script>
  