<template>
    <div class="traitranks-apes-traits  gallery-layout-small">
        <!-- <div class="traitranks-page-gallery"> -->
        <GalleryTopBar :value="selectedTab" @update:value="selectTab" />
        <div class="gallery-banner">
            <img :src="galleryBanner" alt="banner" />
        </div>

        <div class="gallery-items-header">
            <div class="gallery-items-panel">
                <!-- <GallerySort
                        v-model:value="sort"
                        :show-god-power="selectedTab === 'bulls' && subType === 'gods'"
                        :show-meth="selectedTab !== 'teens'"
                        :show-price="subType !== 'burned_teens'"
                        @update:value="sortUpdate"
                    />-->
                <ASelect v-model:value="sort" :items="sortOptins" style="min-width: 260px;" />

                <!-- <GalleryLayoutSwiticher v-model:value="activeLayout" /> -->
                <div class="gallery-items-panel-filters-mob" @click="toggleFilters">
                    <IFilter width="16" />Filters
                </div>
            </div>
            <TraitRanksApesDisclaimer />
            <div class="traitranks-apes-traits-meth">
                <div class="traitranks-apes-traits-meth-balance">
                    <span>Meth Bank</span>
                    <span>{{ methBalance }} METH</span>
                </div>
                <button class="traitranks-apes-traits-meth-buy" @click.prevent="buyMeth">Buy Meth</button>
            </div>

        </div>
        <TraitRanksApesTraitsTabs v-model:value="subTab" :showActivity="false" />

        <div v-if="isLoading" class="gallery-loading-wrapper">
            <img class="loader" src="@/assets/img/spinner-clear.svg" width="150" />
        </div>
        <div v-else class="gallery-tokens-wrapper">
            <TraitRanksOffers :isUtility="true" :buyOrders="offers.buyOrders" :sellOrders="offers.sellOrders"
                @refresh="fetchItems" v-if="subTab === 'offers'" />
            <div class="gallery-items" v-if="subTab === 'items'">
                <div v-for="token in tokens" :key="token.id" class="gallery-item">
                    <TraitRanksUtilityCard :token="token" @click="openModal(token)" @refresh="fetchItems" />
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>
  
<script>
import GalleryFilters from "@/components/TraitRanks/GalleryFilters.vue";
import GalleryTokenCard from "@/components/TraitRanks/GalleryTokenCard.vue";
import GalleryModal from "@/components/TraitRanks/GalleryModal.vue";
import GalleryPagination from "@/components/TraitRanks/GalleryPagination.vue";
import GallerySort from "@/components/TraitRanks/GallerySort.vue";
import AToggler from "@/components/AToggler.vue";

import IFilter from "@/assets/icons/filter.svg?inline";
import GalleryLayoutSwiticher from "@/components/TraitRanks/GalleryLayoutSwitcher.vue";
import GalleryTopBar from "@/components/TraitRanks/GalleryTopBar.vue"
import TraitRanksMethDisclaimer from "@/components/TraitRanks/TraitRanksMethDisclaimer.vue";
import TraitRanksUnderworldDisclaimer from "@/components/TraitRanks/TraitRanksUnderworldDisclaimer.vue";
import TraitRanksFiltersItems from "@/components/TraitRanks/TraitRanksFiltersItems.vue";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { captureError } from "@/helpers/captureError";
import { useToast } from "@/composables/useToast";
import TraitRanksFiltersApesTraits from "../TraitRanksFilters/TraitRanksFiltersApesTraits.vue";
import TraitRanksApesTraitsCard from "../TraitRanksApesTraits/TraitRanksApesTraitsCard.vue";
import ASelect from "@/components/ASelect.vue";
import TraitRanksApesDisclaimer from "../TraitRanksApes/TraitRanksApesDisclaimer.vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { useMethBankStore } from "@/store/meth-bank";
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import TraitRanksApesTraitsActivity from "../TraitRanksApesTraits/TraitRanksApesTraitsActivity.vue";
import TraitRanksApesTraitsTabs from "../TraitRanksApesTraits/TraitRanksApesTraitsTabs.vue";
import utilitiesMetadata from "@/config/utilities-metadata";
import TraitRanksUtilityCard from "../TraitRanksUtilities/TraitRanksUtilityCard.vue";
import { useWeb3Store } from "@/store/web3";
import { Config } from "@/config";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { fetchUtilities } from "@/functions/fetchCollection/fetchUtilities";
import TraitRanksOffers from "../TraitRanksOffers/TraitRanksOffers.vue"

export default {
    components: {
        TraitRanksOffers,
        ASelect,
        TraitRanksApesTraitsTabs,
        TraitRanksApesTraitsActivity,
        TraitRanksApesDisclaimer,
        TraitRanksApesTraitsCard,
        TraitRanksFiltersApesTraits,
        TraitRanksFiltersItems,
        TraitRanksUnderworldDisclaimer,
        TraitRanksMethDisclaimer,
        GalleryTopBar,
        IFilter,
        GalleryLayoutSwiticher,
        AToggler,
        GalleryFilters,
        GalleryTokenCard,
        GalleryModal,
        GalleryPagination,
        GallerySort,
        TraitRanksUtilityCard
    },
    data() {
        return {
            showModal: false,
            'total': 0,
            'tokens': [],
            'isLoading': false,
            'page': 1,
            sortName: "rank",
            sortOrder: "asc",
            selectedTab: 'utilities',
            offers: {},
            subTab: 'items'
        };
    },
    created() {
        this.init()
    },
    computed: {
        methBalance() {

            return formatNumberSpaces(useMethBankStore().balance)
        },

        sort: {
            get() {
                return `${this.sortName}_${this.sortOrder}`
            },
            set(val) {
                const [name, order] = val.split('_')
                this.sortName = name
                this.sortOrder = order
                this.page = 1
                this.refreshTokens()
            }
        },

        sortOptins() {
            return [
                {
                    name: "Id: Low to High",
                    value: 'id_asc'
                },
                {
                    name: "Id: High to Low",
                    value: 'id_desc'
                },
                {
                    name: "Rank: Top to Bottom",
                    value: 'rank_asc'
                },
                {
                    name: "Rank: Bottom to Top",
                    value: 'rank_desc'
                },
                {
                    name: "Total Minted: High to Low",
                    value: "totalMinted_desc"
                },
                {
                    name: "Total Minted: Low to High",
                    value: "totalMinted_asc"
                },
                {
                    name: "Price: Low to High",
                    value: 'price_asc'
                },
                {
                    name: "Price: High to Low",
                    value: 'price_desc'
                },
                {
                    name: "Recently Listed",
                    value: 'listed_desc'
                }
            ]
        },
        categoriesData() {
            return []
        },

        galleryBanner() {
            const banner = require("@/assets/img/banners/1.3.jpg");

            return banner;
        },



    },
    methods: {
        buyMeth() {
            useModal().open({ name: ModalName.BuyMethModal })
        },
        async fetchItems(silent = false) {
            if (!silent) {
                this.isLoading = true;
            }
            const [offers, utilities] = await Promise.all([useApesStorageApi({
                throw: true,
                toast: { error: () => "Couldn't get wallet's sale orders" },
            }).exec({
                url: "/trading/list",
                params: {
                    isUtility: true,
                    buyOrder: true,
                    network: Config.network.name,
                },
            }), useWalletCollectionStore().fetchCollection()])
            const { buyOrders, sellOrders } = offers
            this.offers = offers
            const walletUtilities = Object.values(useWalletCollectionStore().utilities).reduce((arr, item) => arr.concat(item || []), [])
            this.tokens = Object.keys(utilitiesMetadata).map(utilId => {
                const id = parseInt(utilId)
                const buy_orders = buyOrders.filter(offer => offer.trait_id == id)
                const sell_orders = sellOrders.filter(offer => offer.trait_id == id)
                const item = walletUtilities.find(util => util.id == id)
                return {
                    ...utilitiesMetadata[utilId], id,
                    sell_orders,
                    buy_orders,
                    ...item
                }
            })
                // skip incubator
                .filter(util => util.id !== 1 && util.id !== 1109)

            this.isLoading = false;
        },
        async init() {
            this.isLoading = true;
            try {
                await Promise.all([
                    // this.loadTraitsMap(),
                    // this.loadStats(),
                    useMethBankStore().fetchMethBank(),
                    this.fetchItems(),
                ]);
            } catch (err) {
                captureError(err);
            }

            this.isLoading = false;
        },


        sortUpdate() {
            this.page = 1
            this.fetchItems()
        },


        async selectTab(tab) {
            // this.selectedTab = tab;
            this.$emit('update:type', tab)
            return

        },

        async refreshTokens() {
            await this.fetchItems()
            window.scrollTo(0, 0,)
        },



        toggleFilters() {
            this.hideFilters = !this.hideFilters;
        },

        buyNowToggle(value) {

            this.buyNowActive = value;
            if (!value) {
                this.activePrices = [];
            }
            this.refreshTokens();
        },
        sellNowToggle(value) {

            this.sellNowActive = value;
            if (!value) {
                this.activePrices = [];
            }
            this.refreshTokens();
        },
        openModal(token) {
            this.showModal = true;
        },

        updatePage() {
            this.refreshTokens()
        },
    },

};
</script>
  
<style lang="scss">
.traitranks-apes-traits {
    &-meth {
        padding: 10px 0;
        display: flex;
        align-items: center;

        &-balance {
            span {
                &:first-child {
                    opacity: 0.5;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
        }

        &-buy {
            margin-left: 10px;
            padding: 8px 14px;
            background-color: var(--primary);
            border: none;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            font-weight: bold;
            text-transform: uppercase;
            transition: 0.2s;

            &:hover {
                filter: brightness(0.8);
            }
        }
    }
}
</style>