<template>
  <div class="gallery-layout-switcher">
    <div
      class="gallery-layout-switcher-item"
      :class="{ active: value === 'big' }"
      @click="selectItem('big')"
    >
      <IGrid2 />
    </div>
    <div
      class="gallery-layout-switcher-item"
      :class="{ active: value === 'small' }"
      @click="selectItem('small')"
    >
      <IGrid3 />
    </div>
  </div>
</template>
<script>
import IGrid2 from "../../assets/icons/grid-2.svg?inline";
import IGrid3 from "../../assets/icons/grid-3.svg?inline";

export default {
  components: { IGrid2, IGrid3 },
  props: {
    value: String,
  },
  methods: {
    selectItem(value) {
      this.$emit("update:value", value);
    },
  },
};
</script>
