<template>
  <div class="traitranks-page">
    <TraitRanksBulls
      v-if="type === 'bulls' || type === 'teens'"
      :type="type"
      @update:type="onUpdateType"
    />
    <TraitRanksApesTraits
      v-if="type === 'apes_traits'"
      @update:type="onUpdateType"
    />
    <TraitRanksApes
      v-if="type === 'apes'"
      @update:type="onUpdateType"
    />
    <TraitRanksBOTB
      v-if="type === 'botb'"
      @update:type="onUpdateType"
    />
    <TraitRanksUtilities
      v-if="type === 'utilities'"
      @update:type="onUpdateType"
    />
  </div>
</template>

<script>
import TraitRanksBulls from "@/components/TraitRanks/TraitRanksTabs/TraitRanksBulls.vue";
import { defineComponent, ref, watch } from "vue-demi";
import { useRoute, useRouter } from "vue-router";
import TraitRanksApesTraits from "@/components/TraitRanks/TraitRanksTabs/TraitRanksApesTraits.vue";
import TraitRanksApes from "@/components/TraitRanks/TraitRanksTabs/TraitRanksApes.vue";
import TraitRanksBOTB from "@/components/TraitRanks/TraitRanksTabs/TraitRanksBOTB.vue";
import TraitRanksUtilities from "@/components/TraitRanks/TraitRanksTabs/TraitRanksUtilities.vue";

export default defineComponent({
  components: { TraitRanksBulls, TraitRanksApes, TraitRanksApesTraits, TraitRanksBOTB, TraitRanksUtilities },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const types = ['bulls', 'teens','apes', 'apes_traits', 'botb']
    const type = ref('bulls')
    
    const onUpdateType = (newType) => {
      type.value = newType
    }

    router.isReady().then(() => {
      const hash = route.hash.slice(1)
      if (types.includes(hash)) {
        type.value = hash
      } else {
        router.push({ hash: `#${type.value}` })
      }
    })

    watch(type, (newType) => {
      router.push({ hash: `#${newType}` })
    })

    return { type, onUpdateType }
  }

})
</script>
