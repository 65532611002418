<template>
  <div
    class="filter-block filter-slider-block"
    :class="{ open: isOpen }"
  >
    <div
      class="filter-block-title filter-slider-block-title"
      @click="isOpen = !isOpen"
    >
      <span>{{ title }}</span>
      <IArrowDown />
    </div>

    <slot v-if="isOpen" />
  </div>
</template>
<script>
import IArrowDown from "../../assets/icons/arrow-down.svg?inline";
export default {
  components: { IArrowDown },
  props: {
    title: String,
  },
  data() {
    return {
      isOpen: true,
    };
  },
};
</script>
