<template>
  <div
    class="gallery-card"
    :class="{
      'top-1': token.type === 'botb',
      'top-10': token.type === 'evo',
      'top-25': token.type === 'bear',
    }"
  >
    <div class="gallery-card-image">
      <img :src="image" :alt="token.id" width="467" height="467" />
    </div>

    <div class="gallery-card-content">
      <div class="gallery-card-info">
        <span class="gallery-card-rarity">Rank {{ token.rank }}</span>
        <!--<span>{{token.score}}</span>-->
        <span class="gallery-card-token">#{{ token.id }}</span>
        <!-- <span>{{ token.score }}</span> -->
      </div>
      <div v-if="false" class="gallery-card-power">
        <GodBullPowerProgress :progress="token.power" :show-tooltip="false" />
      </div>

      <a v-if="owner && showOwner" class="gallery-card-owner">
        <IUser width="12" />
        <a
          :href="
            'https://opensea.io/' + (token.ownerUsername || token.ownerAddress)
          "
          target="_blank"
          @click.stop
        >
          {{ owner }}
        </a>
      </a>

      <a
        v-if="showOSButton"
        :href="openSeaLink"
        target="_blank"
        class="gallery-card-opensea"
        @click.stop
      >
        <span>{{ token.forSale ? "Buy on OpenSea" : "Go to OpenSea" }}</span>
        <IOpenSea width="25" />
      </a>
    </div>

    <div v-if="token.price" class="gallery-card-price">
      <IEthereum width="12" />
      {{ token.price }}
    </div>
  </div>
</template>

<script>
let flip = false;
import IOpenSea from "@/assets/icons/opensea.svg?inline";
import IEthereum from "@/assets/icons/ethereum.svg?inline";
import { shorten } from "@/helpers/utils";
import IUser from "@/assets/icons/user.svg";
import GodBullPowerProgress from "@/components/Collection/GodBullPowerProgress.vue";
import { Config } from "@/config";

export default {
  components: { IOpenSea, IEthereum, IUser, GodBullPowerProgress },
  props: {
    token: {
      type: Object,
    },
    showOSButton: {
      type: Boolean,
      default: true,
    },
    showOwner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      totalItems: 10000,
      flip,
    };
  },
  computed: {
    owner() {
      return (
        this.token.ownerUsername ||
        (this.token.ownerAddress && shorten(this.token.ownerAddress, 4, 4))
      );
    },
    openSeaLink() {
      if (!this.token.type) console.error("No token type found");
      let address = "";
      switch (this.token.type) {
        case "botb":
          address = Config.BOTBAddress.OG;
          break;
        case "bear":
          address = Config.BOTBAddress.Bears;
          break;
        case "evo":
          address = Config.BOTBAddress.EVO;
          break;
      }
      if (!address) console.error("No address found for BOTB token");
      return `${Config.OSUrl.base}/${address}/${this.token.id}`;
    },
    image() {
      return this.token.image;
    },
  },
  async created() {},
  methods: {
    toggleFlip: function () {
      this.flip = !this.flip;
    },
  },
};
</script>
