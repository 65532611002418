<template>
  <div
    class="app-toggler-wrapper"
    :class="value"
  >
    <span
      class="app-toggler-text"
      :class="{ active: value === tabs[0].value }"
      @click="toggle(tabs[0].value)"
    >{{ tabs[0].name }}</span>
    <div
      class="app-toggler"
      :class="{ active: value === tabs[1].value }"
      @click.prevent="toggle()"
    >
      <span class="app-toggler-active" />
    </div>
    <span
      class="app-toggler-text"
      :class="{ active: value === tabs[1].value }"
      @click="toggle(tabs[1].value)"
    >{{ tabs[1].name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    tabs: Array,
  },
  methods: {
    toggle(value) {
      if (value) {
        this.$emit("update:value", value);
        return;
      }
      if (this.value === this.tabs[1].value) {
        this.$emit("update:value", this.tabs[0].value);
      } else {
        this.$emit("update:value", this.tabs[1].value);
      }
    },
  },
};
</script>
