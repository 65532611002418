<template>
  <div class="filter-block filter-search-wrapper">
    <div class="filter-block-title">
      Search
    </div>
    <div class="filter-search">
      <ISearch />
      <GalleryFilterSearchSelect
        v-if="showSelect"
        v-model:value="searchType"
        :items="selectItems"
      />
      <input
        v-model="searchPhrase"
        type="text"
        name
        :placeholder="searchType === 'id' ? 'Enter #' : 'Enter wallet address'"
        class="filter-search-input"
        @keyup="search()"
      >
    </div>
    <p
      v-if="searchType === 'id'"
      class="filter-search-tooltip"
    >
      separate multiple IDs with a comma or space
    </p>
    <p
      v-if="searchType === 'wallet'"
      class="filter-search-tooltip"
    >
      enter wallet address or username
    </p>
  </div>
</template>
<script>
import ISearch from "../../assets/icons/search.svg?inline";
import GalleryFilterSearchSelect from "./GalleryFilterSearchSelect.vue";
export default {
  components: { ISearch, GalleryFilterSearchSelect },
  props: {
    showSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectItems: [
        {
          name: "ID",
          value: "id",
        },
        {
          name: "Wallet",
          value: "wallet",
        },
      ],
      searchPhrase: "",
      searchType: "id",
    };
  },
  watch: {
    searchType() {
      this.searchPhrase = "";
      this.$emit("search", { type: this.searchType, value: this.searchPhrase });
    },
  },
  methods: {
    search() {
      this.$emit("search", { type: this.searchType, value: this.searchPhrase });
    },
  },
};
</script>
