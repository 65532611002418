<template>
  <div class="filter-slider-wrapper filter-rank">
    <Slider
      v-if="max"
      v-model="sliderModel"
      class="filter-slider filter-rank-slider"
      :min="1"
      :max="max"
      :tooltips="true"
      :show-tooltip="'always'"
      :tooltip-position="'top'"
      :merge="max / 8"
      @change="onChange"
    />
    <div class="filter-rank-crumbs">
      <a
        v-for="(item, idx) in crumbs"
        :key="idx"
        href="#"
        class="filter-rank-crumb"
        @click.prevent="selectItem(item.value)"
      >{{ item.name }}</a>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
export default {
  components: { Slider },

  props: {
    max: Number,
    value: Array,
  },
  emits: ["update:value"],
  data() {
    return {
      min: 1,
      crumbs: [
        {
          name: '1%',
          value: 1
        },
        {
          name: "10%",
          value: 10
        },
        {
          name: "25%",
          value: 25
        },
      ]
    };
  },
  computed: {
    sliderModel: {
      get() {
        return this.value.length ? this.value : [this.min, this.max];
      },
      set(val) {
        this.onChange(val);
      },
    },
  },

  watch: {
    max() {
      this.onChange([this.min, this.max]);
    },
  },
  methods: {
    selectItem(value) {
      const percents = value / 100
      const endValue = Math.round(this.max * percents)
      this.onChange([this.min, endValue])
    },
    async onChange(newValue) {
      await this.$nextTick();
      if (newValue[0] == this.min && newValue[1] == this.max) {
        this.$emit("update:value", []);
        return;
      }
      this.$emit(
        "update:value",
        newValue.map((val) => parseFloat(val))
      );
    },
  },
};
</script>


