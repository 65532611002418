<template>
  <TraitRanksFilters>
    <GalleryFiltersSearch
      :show-select="false"
      @search="$emit('search', $event)"
    />
    <GallerySliderBlock title="RANK">
      <GalleryFilterRank
        v-if="maxRank"
        class="filter-offset"
        :value="activeRanks"
        :max="maxRank"
        @update:value="$emit('rankUpdate', $event)"
      />
    </GallerySliderBlock>
    <div class="filter-block filter-block-attributes">
      <div class="filter-block-title">
        Attributes
        <button
          class="filter-block-attributes-expand"
          :class="{ expanded: isExpandedAll }"
          @click="expandAll"
        >
          <span>{{ isExpandedAll ? "Collapse all" : "Expand all" }}</span>
          <IDoubleArrowDown />
        </button>
      </div>
      <div
        v-if="false"
        class="filter-block-attributes-gap"
      >
        <div class="filter-block-attributes-gap-title">
          <div class="filter-block-attributes-gap-title-inner">
            Floor Gap
            <span class="filter-block-attributes-gap-title-new">NEW</span>
            <IInfo width="12" />
          </div>
          <div class="filter-block-attributes-gap-tooltip">
            Floor gap is the difference in ETH between current floor bull price
            and the next lowest for each trait
          </div>
        </div>
        <!-- <ASwitcher v-model:value="activeDealPrice" /> -->
      </div>
      <FilterItem
        v-for="attribute in attributes"
        :key="attribute.name"
        v-model:isOpen="filterOpen[attribute.name]"
        :filter="attribute"
        :selected-traits="selectedTraits"
        :active-deal-price="activeDealPrice"
        @update:value="$emit('update:attributes', $event)"
      />
    </div>
  </TraitRanksFilters>
</template>
<script>
import { computed, defineComponent, ref } from "vue"
import TraitRanksFilters from "./TraitRanksFilters.vue"
import GalleryFiltersSearch from "../GalleryFiltersSearch.vue"
import FilterItem from "../FilterItem.vue"
import IDoubleArrowDown from "@/assets/icons/double-arrow-down.svg?inline";
import IClose from "@/assets/icons/close.svg?inline";
import IFilter from "@/assets/icons/filter.svg?inline";
import IInfo from "@/assets/img/info.svg?inline";
import ASwitcher from "@/components/ASwitcher.vue";
import GallerySliderBlock from "../GallerySliderBlock.vue";
import GalleryFilterRank from "../GalleryFilterRank.vue";
export default defineComponent({
    components: {
        FilterItem, TraitRanksFilters, GalleryFiltersSearch, IDoubleArrowDown, GalleryFilterRank,
        IClose,
        IFilter,
        IInfo,
        ASwitcher, GallerySliderBlock
    },
    props: {
        filters: Object,
        selectedTraits: {
            type: Array
        },
        activeRanks: Array,
        maxRank: Number
    },
    data() {
        return {
            activeDealPrice: false,
            filterOpen: {}
        }
    },

    computed: {
        isExpandedAll() {
            return Object.keys(this.filters).filter((key) => {
                return this.filterOpen[key];
            }).length;
        },
        attributes() {
            const attributeNames = Object.values(this.filters);
            if (!this.activeDealPrice) return attributeNames;
            const sortedAttributes = Object.keys(this.filters).map((attrName) => {
                const { name, traits } = this.filters[attrName];
                const sortedTraits = [...traits].sort(
                    (a, b) => (b.gap || 0) - (a.gap || 0)
                );
                return { name, traits: sortedTraits };
            });
            sortedAttributes.sort(
                (a, b) => (b.traits[0].gap || 0) - (a.traits[0].gap || 0)
            );
            return sortedAttributes;
        },
    },
    methods: {
        expandAll() {
            const isExpandedAll = this.isExpandedAll;
            Object.keys(this.filters).map((key) => {
                if (isExpandedAll) {
                    this.filterOpen[key] = false;
                } else {
                    this.filterOpen[key] = true;
                }
            });
        },
    }
})
</script>
<style lang="scss"></style>