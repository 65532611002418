<template>
    <TraitRanksFilters>
        <TraitRanksApesTraitsFiltersSearch @search="$emit('search', $event)" />
        <FilterSwitcher title="Buy now" :value="buyNowActive" @toggle="$emit('buyNow', $event)" />
        <FilterSwitcher title="Accept offers" :value="sellNowActive" @toggle="$emit('sellNow', $event)" />

        <FilterItem
            v-model:isOpen="filterOpen['gender']"
            :filter="genderFilter"
            :selected-traits="activeGender"
            :show-search="false"
            @update:value="$emit('update:gender', $event)"
        />
        <FilterItem
            v-model:isOpen="filterOpen['rarity']"
            :filter="rarityFilter"
            :selected-traits="activeRarity"
            :show-search="false"
            @update:value="$emit('update:rarity', $event)"
        />
        <FilterItem
            v-model:isOpen="filterOpen['source']"
            :filter="sourceFilter"
            :selected-traits="activeSource"
            :show-search="false"
            @update:value="onUpdateSource"
        />
        <FilterItem
            v-model:isOpen="filterOpen['tags']"
            :filter="tagsFilter"
            :selected-traits="activeTags"
            :show-search="false"
            @update:value="onUpdateTags"
        />

        <FilterItem
            v-model:isOpen="filterOpen['categories']"
            :filter="categoryFilter"
            :selected-traits="activeCategories"
            @update:value="$emit('update:categories', $event)"
        />
    </TraitRanksFilters>
</template>
<script>
import { computed, defineComponent, ref } from "vue"
import TraitRanksFilters from "./TraitRanksFilters.vue"
import GalleryFiltersSearch from "../GalleryFiltersSearch.vue"
import FilterItem from "../FilterItem.vue"
import { useApiGetApeCategories } from "@/api/apes/get-categories"
import TraitRanksApesTraitsFiltersSearch from "../TraitRanksApesTraits/TraitRanksApesTraitsFiltersSearch.vue"
import FilterSwitcher from "../FilterSwitcher.vue"
export default defineComponent({
    components: { FilterItem, FilterSwitcher, TraitRanksApesTraitsFiltersSearch, TraitRanksFilters, GalleryFiltersSearch },
    props: {
        buyNowActive: Boolean,
        sellNowActive: Boolean,
        gender: Number,
        categories: {
            type: Array,
            default: () => []
        },
        tags: String,
        source: String,
        rarity: String
    },
    setup(props, { emit }) {
        const filterOpen = ref({
            gender: false,
            categories: false,
            rarity: false,
            source: false,
            tags: true
        })
        const genderFilter = {
            name: 'Gender',
            traits: [
                {
                    name: 'All'
                },
                {
                    name: 'Male'
                },
                {
                    name: 'Female'
                },
                {
                    name: 'Unisex'
                },

            ]
        }
        const rarityFilter = {
            name: 'Type',
            traits: [
                {
                    name: 'All'
                },
                {
                    name: 'Legendary'
                },
                {
                    name: 'Epic'
                },
                {
                    name: 'Common'
                },

            ]
        }
        const sourceFilter = {
            name: 'Origin',
            traits: [
                {
                    name: 'Og Mint',
                    value: "og mint"
                },
                {
                    name: 'Achievements',
                    value: "achievements"
                },
                {
                    name: 'Formulas',
                    value: "formula"
                },
                {
                    name: 'The Shop',
                    value: "shop"
                },
                {
                    name: 'Loot Box',
                    value: "lootbox"
                },

            ]
        }
        const tagsFilter = {
            name: 'Tags',
            traits: [
                {
                    name: 'GAQ',
                    value: "GAQ"
                },

            ]
        }
        const activeSource = computed(() => {
            const activeItem = sourceFilter.traits.find(item => item.value === props.source)?.name
            return activeItem ? [{ name: "Origin", trait: activeItem, active: true }] : []
        })
        const activeTags = computed(() => {
            const activeItem = tagsFilter.traits.find(item => item.value === props.tags)?.name
            return activeItem ? [{ name: "Tags", trait: activeItem, active: true }] : []
        })
        const onUpdateSource = (value) => {
            const newSource = sourceFilter.traits.find(item => item.name === value.trait)?.value
            if (newSource === props.source) {
                emit('update:source', '')
                return
            }
            emit('update:source', newSource)
        }
        const onUpdateTags = (value) => {
            const newTags = tagsFilter.traits.find(item => item.name === value.trait)?.value
            if (newTags === props.tags) {
                emit('update:tags', '')
                return
            }
            emit('update:tags', newTags)
        }
        const activeRarity = computed(() => {
            if (!props.rarity) return [
                {
                    name: "Type",
                    trait: "All",
                    active: true
                }
            ]
            return [
                {
                    name: "Type",
                    trait: props.rarity[0].toUpperCase() + props.rarity.slice(1),
                    active: true
                }
            ]
        })


        const categoryFilter = ref({
            name: "Category",
            traits: [

            ]
        })
        const activeGender = computed(() => {
            if (!props.gender) {
                return [
                    {
                        name: "Gender",
                        trait: "All",
                        active: true
                    }
                ]
            }
            if (props.gender === 1) {
                return [
                    {
                        name: "Gender",
                        trait: "Male",
                        active: true
                    }
                ]
            }
            if (props.gender === 2) {
                return [
                    {
                        name: "Gender",
                        trait: "Female",
                        active: true
                    }
                ]
            }
            if (props.gender === 3) {
                return [
                    {
                        name: "Gender",
                        trait: "Unisex",
                        active: true
                    }
                ]
            }

            return []
        })
        const fetchCategories = async () => {
            let categories = await useApiGetApeCategories({
                toast: { error: true },
            }).exec();
            categoryFilter.value.traits = categories
        }
        fetchCategories()
        const activeCategories = computed(() => {
            return props.categories.map(cat => ({
                name: "Category",
                active: true,
                trait: cat.name
            }))
        })
        return {
            filterOpen, activeSource,activeTags,tagsFilter,onUpdateTags,
            onUpdateSource, sourceFilter, activeRarity, rarityFilter, activeGender, activeCategories, categoryFilter, genderFilter
        }
    }
})
</script>
<style lang="scss"></style>