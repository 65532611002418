<template>
  <div class="traitranks-page-filters">
    <div class="filter-header">
      <div class="filter-header-title">
        <IFilter width="20" />Filters
      </div>
      <div
        class="gallery-filters-close"
        @click="$emit('toggle')"
      >
        <IClose width="24" />
      </div>
    </div>

    <slot />
  </div>
</template>
  
<script>

import IClose from "@/assets/icons/close.svg?inline";
import IFilter from "@/assets/icons/filter.svg?inline";

export default {
    components: {
        IFilter,
        IClose,

    },
    props: {

    },


};
</script>
  