<template>
  <div class="gallery-modal-wrapper">
    <div class="gallery-modal" :class="{ fullscreen: isFullScreen }">
      <a href="#" class="gallery-modal-close" @click.prevent="$emit('close')">
        <IClose width="25" />
      </a>
      <a
        v-if="isFullScreen"
        href="#"
        class="gallery-modal-back button-back"
        @click.prevent="closeFullScreen"
      >
        <IArrowLeft />
        <span>Back</span>
      </a>
      <div class="gallery-modal-row">
        <div class="gallery-modal-image">
          <img :src="token.image" alt="image">
          <a
            v-if="!isFullScreen"
            href="#"
            class="gallery-modal-fullscreen-btn"
            @click.prevent="openFullScreen"
          >
            <IFullScreen />
          </a>
        </div>
        <div v-if="!isFullScreen" class="gallery-modal-info">
          <div class="gallery-modal-rank">
            RANK {{ token.rank }}
            <a v-if="showOSButton" :href="OSLink" target="_blank">
              <IOpenSea width="21" />
            </a>
          </div>
          <div class="gallery-modal-tokenid">
#{{ token.id }}
</div>
          <div v-if="token.type === 'Bull'" class="gallery-modal-breedings">
            Breedings Left:
            <span>{{ breedingsLeft }}</span>
</div>

          <div class="gallery-modal-traits-title">
Traits:
</div>
          <div class="gallery-modal-traits">
            <div v-for="(trait, idx) in token.traits" :key="idx" class="gallery-modal-trait">
              <div class="gallery-modal-trait-name">
{{ trait.trait_type }}
</div>
              <div class="gallery-modal-trait-value">
{{ trait.value }}
</div>
              <div v-if="!isBOTBToken" class="gallery-modal-trait-count">
{{ trait.count }} ({{ trait.occurrences }}%)
</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gallery-modal-overlay" @click="$emit('close')" />
  </div>
</template>
<script>
import IClose from "../../assets/icons/close.svg?inline";
import IOpenSea from "../../assets/icons/opensea.svg?inline";
import IFullScreen from "../../assets/icons/fullscreen.svg?inline";
import IArrowLeft from "../../assets/icons/arrow-left-long.svg?inline";
import IRefresh from "../../assets/icons/refresh.svg?inline";
import ICheckMark from "../../assets/icons/checkmark.svg?inline";

import { Config } from "@/config";

export default {
  components: { IClose, IRefresh, ICheckMark, IOpenSea, IFullScreen, IArrowLeft },
  props: {
    token: Object,
    showOSButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isFullScreen: false,
  }),
  computed: {
    breedingsLeft() {
      return this.token.traits.find(trait => trait.trait_type.toLowerCase() === 'breedings left')?.value
    },
    isBOTBToken() {
      console.log(this.token.type)
      return this.token.type === "botb" || this.token.type === "bear" || this.token.type === "evo"
    },
    OSLink() {
      if(this.isBOTBToken) {
        let address = ""
        switch (this.token.type) {
          case "botb":
            address = Config.BOTBAddress.OG
            break
          case "bear":
            address = Config.BOTBAddress.Bears
            break
          case "evo":
            address = Config.BOTBAddress.EVO
            break
        }
        if (!address) console.error("No address found for BOTB token");
        return `${Config.OSUrl.base}/${address}/${this.token.id}`
      }

      if (this.token.type === "Ape") {
        return this.collectionUrl + this.token.token_id
      }
      return this.collectionUrl + this.token.id
    },
    collectionUrl() {
      if (this.token.isTeen) {
        Config.OSUrl.teens
      }
      else if (this.token.type === "Ape") {
        return Config.OSUrl.apes
      }
      return Config.OSUrl.genesis
    },
  },
  methods: {
    openFullScreen() {
      this.isFullScreen = true
    },
    closeFullScreen() {
      this.isFullScreen = false

    }
  },
};
</script>
