<template>
    <div class="traitranks-apes-traits">
        <TraitRanksFiltersApesTraits :class="{ active: !hideFilters && !isLoading }" :gender="activeGender"
            :categories="activeCategories" :buy-now-active="buyNowActive" :sell-now-active="sellNowActive"
            :rarity="activeRarity" :source="source" :tags="tags" @buyNow="buyNowToggle" @sellNow="sellNowToggle"
            @search="onSearch" @toggle="toggleFilters" @update:gender="onUpdateGender" @update:rarity="onUpdateRarity"
            @update:source="onUpdateSource" @update:categories="onUpdateCategoreis" @update:tags="onUpdateTags" />
        <div class="traitranks-page-gallery" :class="'gallery-layout-' + activeLayout">
            <GalleryTopBar :value="selectedTab" @update:value="selectTab" />
            <div class="gallery-banner">
                <img :src="galleryBanner" alt="banner" />
            </div>

            <div class="gallery-items-header">
                <div class="gallery-items-panel">
                    <!-- <GallerySort
                        v-model:value="sort"
                        :show-god-power="selectedTab === 'bulls' && subType === 'gods'"
                        :show-meth="selectedTab !== 'teens'"
                        :show-price="subType !== 'burned_teens'"
                        @update:value="sortUpdate"
                    />-->
                    <ASelect v-model:value="sort" :items="sortOptins" style="min-width: 260px;" />

                    <!-- <GalleryLayoutSwiticher v-model:value="activeLayout" /> -->
                    <div class="gallery-items-panel-filters-mob" @click="toggleFilters">
                        <IFilter width="16" />Filters
                    </div>
                </div>
                <TraitRanksApesDisclaimer />
                <div class="traitranks-apes-traits-meth">
                    <div class="traitranks-apes-traits-meth-balance">
                        <span>Meth Bank</span>
                        <span>{{ methBalance }} METH</span>
                    </div>
                    <button class="traitranks-apes-traits-meth-buy" @click.prevent="buyMeth">Buy Meth</button>
                </div>
                <TraitRanksFiltersItems v-if="hasActiveFilters" :items="selectedTraits" @clear="clearFilters"
                    @select="updateAttributes" />
            </div>
            <TraitRanksApesTraitsTabs v-model:value="subTab" />
            <div v-if="isLoading" class="gallery-loading-wrapper">
                <img class="loader" src="@/assets/img/spinner-clear.svg" width="150" />
            </div>
            <div v-else class="gallery-tokens-wrapper">
                <div class="gallery-items" v-if="subTab === 'items'">
                    <div v-for="token in tokens" :key="token.id" class="gallery-item">
                        <!-- <GalleryTokenCard
                            :token="token"
                            :use-menu="false"
                            :show-o-s-button="subType !== 'burned_teens'"
                            :show-owner="subType !== 'burned_teens'"
                            :total-items="total"
                            @click="openModal(token)"
                        />-->
                        <TraitRanksApesTraitsCard :item="token" @refresh="fetchItems(true)" />
                    </div>
                </div>
                <TraitRanksApesTraitsActivity v-if="subTab === 'activity'" />
                <TraitRanksOffers v-if="subTab === 'offers'" :isUtility="false" />
            </div>

            <GalleryPagination v-if="subTab === 'items'" v-model:page="page" v-model:pageSize="perPage" :total-items="total"
                :total-filtered-items="perPage" :items-name="'Apes Traits'" @update:page="updatePage" />
        </div>
    </div>
</template>
  
<script>
import GalleryFilters from "@/components/TraitRanks/GalleryFilters.vue";
import GalleryTokenCard from "@/components/TraitRanks/GalleryTokenCard.vue";
import GalleryModal from "@/components/TraitRanks/GalleryModal.vue";
import GalleryPagination from "@/components/TraitRanks/GalleryPagination.vue";
import GallerySort from "@/components/TraitRanks/GallerySort.vue";
import AToggler from "@/components/AToggler.vue";

import IFilter from "@/assets/icons/filter.svg?inline";
import GalleryLayoutSwiticher from "@/components/TraitRanks/GalleryLayoutSwitcher.vue";
import GalleryTopBar from "@/components/TraitRanks/GalleryTopBar.vue"
import TraitRanksMethDisclaimer from "@/components/TraitRanks/TraitRanksMethDisclaimer.vue";
import TraitRanksUnderworldDisclaimer from "@/components/TraitRanks/TraitRanksUnderworldDisclaimer.vue";
import TraitRanksFiltersItems from "@/components/TraitRanks/TraitRanksFiltersItems.vue";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { captureError } from "@/helpers/captureError";
import { useToast } from "@/composables/useToast";
import TraitRanksFiltersApesTraits from "../TraitRanksFilters/TraitRanksFiltersApesTraits.vue";
import TraitRanksApesTraitsCard from "../TraitRanksApesTraits/TraitRanksApesTraitsCard.vue";
import ASelect from "@/components/ASelect.vue";
import TraitRanksApesDisclaimer from "../TraitRanksApes/TraitRanksApesDisclaimer.vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { useMethBankStore } from "@/store/meth-bank";
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import TraitRanksApesTraitsActivity from "../TraitRanksApesTraits/TraitRanksApesTraitsActivity.vue";
import TraitRanksApesTraitsTabs from "../TraitRanksApesTraits/TraitRanksApesTraitsTabs.vue";
import TraitRanksOffer from "../TraitRanksOffers/TraitRanksOffer.vue";
import TraitRanksOffers from "../TraitRanksOffers/TraitRanksOffers.vue";
import { Config } from "@/config";


export default {
    components: {
        ASelect,
        TraitRanksApesTraitsTabs,
        TraitRanksApesTraitsActivity,
        TraitRanksApesDisclaimer,
        TraitRanksApesTraitsCard,
        TraitRanksFiltersApesTraits,
        TraitRanksFiltersItems,
        TraitRanksUnderworldDisclaimer,
        TraitRanksMethDisclaimer,
        GalleryTopBar,
        IFilter,
        GalleryLayoutSwiticher,
        AToggler,
        GalleryFilters,
        GalleryTokenCard,
        GalleryModal,
        GalleryPagination,
        GallerySort,
        TraitRanksOffer,
        TraitRanksOffers
    },
    data() {
        return {
            offers: {},
            hideFilters: true,
            activeLayout: "big",
            subTab: 'items',
            showModal: false,
            'maxPrice': 0,
            'total': 0,
            'tokens': [],
            selectedTraits: [],
            activePrices: [],
            'buyNowActive': false,
            'sellNowActive': false,
            'searchName': '',
            'searchIds': [],
            'isLoading': false,
            activeRarity: '',
            'page': 1,
            // id, total_minted
            source: '',
            tags: '',
            sortName: "rank",
            sortOrder: "asc",
            perPage: 48,
            selectedTab: 'apes_traits',
            activeGender: 0,
            activeCategories: [],
            boxType: undefined
        };
    },
    created() {
        this.init()
    },
    computed: {
        methBalance() {

            return formatNumberSpaces(useMethBankStore().balance)
        },

        sort: {
            get() {
                return `${this.sortName}_${this.sortOrder}`
            },
            set(val) {
                const [name, order] = val.split('_')
                this.sortName = name
                this.sortOrder = order
                this.page = 1
                this.refreshTokens()
            }
        },

        sortOptins() {
            return [
                {
                    name: "Id: Low to High",
                    value: 'id_asc'
                },
                {
                    name: "Id: High to Low",
                    value: 'id_desc'
                },
                {
                    name: "Rank: Top to Bottom",
                    value: 'rank_asc'
                },
                {
                    name: "Rank: Bottom to Top",
                    value: 'rank_desc'
                },
                {
                    name: "Total Minted: High to Low",
                    value: "totalMinted_desc"
                },
                {
                    name: "Total Minted: Low to High",
                    value: "totalMinted_asc"
                },
                {
                    name: "Price: Low to High",
                    value: 'price_asc'
                },
                {
                    name: "Price: High to Low",
                    value: 'price_desc'
                },
                {
                    name: "Recently Listed",
                    value: 'listed_desc'
                }
            ]
        },
        categoriesData() {
            return []
        },

        galleryBanner() {
            const banner = require("@/assets/img/banners/1.3.jpg");

            return banner;
        },

        hasActiveFilters() {
            return (
                this.selectedTraits.length > 0 ||
                this.activePrices.length ||
                this.buyNowActive ||
                this.sellNowActive
            );
        },

    },
    methods: {
        buyMeth() {
            useModal().open({ name: ModalName.BuyMethModal })
        },
        async fetchItems(silent = false) {
            if (!silent) {
                this.isLoading = true;
            }
            try {
                const skip = (this.page - 1) * this.perPage;
                const data = await useApesStorageApi({
                    throw: true,
                }).exec({
                    url: "/metadata/traits-search",
                    params: {
                        sort: this.sortName === 'totalMinted' ? 'total_minted' : this.sortName,
                        order: this.sortOrder === "asc" ? undefined : this.sortOrder,
                        limit: this.perPage,
                        skip,
                        rarity: this.activeRarity,
                        source: this.source,
                        buy_now: this.buyNowActive,
                        sell_now: this.sellNowActive,
                        category: this.activeCategories.map(item => item.name),
                        name: this.searchName,
                        id: this.searchIds,
                        tags: this.tags,
                        gender: this.activeGender ? this.activeGender : undefined
                    },

                });
                if (!data) {
                    throw new Error("Error while fetching apes traits", { cause: data });
                }
                const { count, result: tokens } = data;

                this.total = count;
                this.tokens = tokens;
            } catch (err) {
                useToast().error({ title: "Error while loading items" });
                captureError(err);
            }
            this.isLoading = false;
        },
        async init() {
            this.isLoading = true;
            try {
                await Promise.all([
                    // this.loadTraitsMap(),
                    // this.loadStats(),
                    useMethBankStore().fetchMethBank(),
                    this.fetchItems(),
                ]);
            } catch (err) {
                captureError(err);
            }

            this.isLoading = false;
        },
        updateAttributes({ name, trait, active }) {
            if (active) {
                this.selectedTraits.push({ name, trait })
            } else {
                const idx = this.selectedTraits.findIndex(item => (item.trait === trait && item.name === name))
                this.selectedTraits.splice(idx, 1)
            }
            this.page = 1
            this.refreshTokens()
        },
        onUpdateGender(value) {

            let newGender = 0
            if (value.trait === 'Male') {
                newGender = 1
            } else if (value.trait === 'Female') {
                newGender = 2
            } else if (value.trait === 'Unisex') {
                newGender = 3
            } else if (value.trait === "All") {
                newGender = 0
            }

            this.activeGender = newGender
            this.page = 1
            this.refreshTokens()
        },
        onUpdateRarity(value) {
            const newRarity = value.trait.toLowerCase()
            if (newRarity === 'all') {
                this.activeRarity = ''
            } else {
                this.activeRarity = newRarity
            }
            this.page = 1
            this.refreshTokens()
        },
        onUpdateSource(value) {
            this.source = value
            this.page = 1
            this.refreshTokens()
        },
        onUpdateTags(value) {
            this.tags = value
            this.page = 1
            this.refreshTokens()
        },
        onUpdateCategoreis({ active, trait }) {
            if (active) {
                this.activeCategories.push({ name: trait })
            } else {
                const idx = this.activeCategories.findIndex(item => item.name === trait)
                this.activeCategories.splice(idx, 1)
            }
            this.page = 1
            this.refreshTokens()
        },

        clearAttributes() {
            this.selectedTraits = []
            this.page = 1

        },
        clearFilters() {
            this.clearAttributes()
            this.activePrices = []

            this.buyNowActive = false
            this.sellNowActive = false
            this.page = 1

            this.refreshTokens();
        },

        sortUpdate() {
            this.page = 1
            this.fetchItems()
        },
        priceUpdate(newPrices) {
            this.page = 1

            this.activePrices = newPrices;
            if (newPrices[0] > this.minPrice || newPrices[1] < this.maxPrice) {
                this.buyNowActive = true;
            } else {
                this.buyNowActive = false;
            }
            this.refreshTokens();
        },
        methUpdate(newMeth) {
            this.page = 1
            this.activeMeth = newMeth;
            this.refreshTokens();
        },

        onSearch({ type, value }) {
            if (type === "id") {
                this.searchIds = value
                    .split(/[,\s;]/)
                    .map((item) => {
                        return parseInt(item);
                    })
                    .filter((item) => !isNaN(item));
                this.searchName = ''
            } else if (type === "name") {
                this.searchName = value
                this.searchIds = [];
            }
            this.page = 1

            this.refreshTokens();
        },
        async selectTab(tab) {
            // this.selectedTab = tab;
            this.$emit('update:type', tab)
            return

        },

        async refreshTokens() {
            await this.fetchItems()
            window.scrollTo(0, 0,)
        },



        toggleFilters() {
            this.hideFilters = !this.hideFilters;
        },

        buyNowToggle(value) {

            this.buyNowActive = value;
            if (!value) {
                this.activePrices = [];
            }
            this.refreshTokens();
        },
        sellNowToggle(value) {

            this.sellNowActive = value;
            if (!value) {
                this.activePrices = [];
            }
            this.refreshTokens();
        },
        openModal(token) {
            this.showModal = true;
        },

        updatePage() {
            this.refreshTokens()
        },
    },

};
</script>
  
<style lang="scss">
.traitranks-apes-traits {
    &-meth {
        padding: 10px 0;
        display: flex;
        align-items: center;

        &-balance {
            span {
                &:first-child {
                    opacity: 0.5;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
        }

        &-buy {
            margin-left: 10px;
            padding: 8px 14px;
            background-color: var(--primary);
            border: none;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            font-weight: bold;
            text-transform: uppercase;
            transition: 0.2s;

            &:hover {
                filter: brightness(0.8);
            }
        }
    }
}
</style>