<template>
  <div
    class="traitranks-ape-trait"
    :class="{ 'gender-both': genderId === 3, [`rarity-${rarity}`]: true }"
  >
    <div class="traitranks-ape-trait__category">{{ item.categoryName }}</div>
    <div
      v-if="item.balance > 1"
      v-tooltip="'You have ' + item.balance + ' such traits in the wallet'"
      class="traitranks-ape-trait__count"
    >
      <IWallet width="14" />
      {{ item.balance }}
    </div>
    <div v-if="genderId === 3" class="traitranks-ape-trait__images">
      <div class="traitranks-ape-trait__image" @click.prevent="zoomImage(maleImage)">
        <img :src="maleImage" alt="male" loading="lazy">
        <div class="traitranks-ape-trait__image-info">Male</div>
      </div>
      <div class="traitranks-ape-trait__image" @click.prevent="zoomImage(femaleImage)">
        <img :src="femaleImage" alt="female" loading="lazy">
        <div class="traitranks-ape-trait__image-info">Female</div>
      </div>
    </div>
    <div v-else class="traitranks-ape-trait__images">
      <div class="traitranks-ape-trait__image">
        <img
          :src="previewImage"
          alt="preview"
          loading="lazy"
          @click.prevent="zoomImage(previewImage)"
        >
        <div class="traitranks-ape-trait__image-info">{{ genderId === 1 ? 'Male' : 'Female' }}</div>
      </div>
    </div>
    <div class="traitranks-ape-trait__info">
      <div class="traitranks-ape-trait__id">#{{ item.id }}</div>
      <div class="traitranks-ape-trait__name">{{ item.name }}</div>

      <div class="traitranks-ape-trait__opensea">
        <a :href="OSUrl" target="_blank">
          <IOpenSea width="16" />
        </a>
      </div>
    </div>
    <div class="traitranks-ape-trait__info traitranks-ape-trait__info-2">
      <div class="traitranks-ape-trait__rank">
        RANK
        <span>{{ item.rank }}</span>
      </div>
      <div
        v-tooltip="{
          content: tooltipContent,
          // content: item.total_minted + ' Minted - ' + item.burned + ' Burned',
          distance: 5,
          html: true,
        }"
        class="traitranks-ape-trait__minted-count"
      >
        {{ item.total_minted - item.burned }} Remaining 
        <IInfo width="10" />
        <br/>
        ({{ clampedPercentage }}% Minted)
      </div>
    </div>
    <div class="traitranks-ape-trait__additional">
      <div v-if="showAdditional" class="traitranks-ape-trait__category-rank">
        Category Rank
        <span>{{ item.category_rank }}</span>
      </div>
      <div v-if="showAdditional" class="traitranks-ape-trait__score">
        Score
        <span>{{ item.score }}</span>
      </div>
    </div>
    <div class="traitranks-ape-trait__sell-order">
      <div v-if="hasSellOrders " class="traitranks-ape-trait__price">
        <img src="@/assets/img/meth.png" alt="meth" width="15">        
        <span class="traitranks-ape-trait__minted-count"> 
          Buy it for  
        </span>   
        <span class="traitranks-ape-trait__trading-price">
          {{ sellOrder.price }} METH 
        </span>   
      </div>
      <div v-if="hasBuyOrders" class="traitranks-ape-trait__price">
        <img src="@/assets/img/meth.png" alt="meth" width="15">        
        <span class="traitranks-ape-trait__minted-count"> 
          Sell it for     
        </span>   
        <span class="traitranks-ape-trait__trading-price">
          {{ buyOrder?.price }} METH          
        </span>
      </div>
    </div>
    <a
      href="javascript:void(0)"
      class="traitranks-ape-trait__buy"
      @click.prevent="buyTrait"
      >Trade</a>   
    <div class="traitranks-ape-trait__rarity" :class="rarity">{{ rarityName }}</div>
  </div>
</template>
<script>
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
import { computed, defineComponent } from "vue";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";
import IOpenSea from "@/assets/icons/opensea.svg?inline";
import IInfo from "@/assets/img/info.svg?inline";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { Config } from "@/config";
export default defineComponent({
  components: { IWallet, IOpenSea, IInfo },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    // add gender id, rarity, and balance to trait card
    const genderId = computed(() => props.item.genderId)
    const maleImage = computed(() => resizeImageCF(props.item.malePreviewImageSrc, 512))
    const femaleImage = computed(() => resizeImageCF(props.item.femalePreviewImageSrc, 512))
    const previewImage = computed(() => maleImage.value || femaleImage.value)
    const sellOrders = computed(() => [...props.item.sell_orders].sort((a, b) => a.price - b.price))
    const buyOrders = computed(() => [...props.item.buy_orders])
    const sellOrder = computed(() => sellOrders.value[0])
    const buyOrder = computed(() => buyOrders.value[0])
    const rarityName = computed(() => {
      if (props.item.rarity === ApeTraitRarity.og) return 'OG Mint';
      if (props.item.rarity) {
        return props.item.rarity.charAt(0).toUpperCase() + props.item.rarity.slice(1)
              }
      return ''
    })
    const rarity = computed(() => {
      if (props.item.rarity === ApeTraitRarity.og) return 'common'
      return props.item.rarity
    })
    const zoomImage = (image) => {
      useModal().open({ name: ModalName.ImageModal, props: { image } })
    }
    const OSUrl = computed(() => Config.OSUrl.apeTraits + props.item.id)
    const hasSellOrders = computed(() => props.item.sell_orders?.length)
    const hasBuyOrders = computed(() => props.item.buy_orders?.length)
    // const showAdditional = Config.mode !== 'production'
    const buyTrait = () => {
      useModal().open({
        name: ModalName.BuyTraitModal, props: {
          sellOrders: sellOrders.value,
          buyOrders: buyOrders.value,
          trait: props.item,
          onSuccess: () => {
            emit('refresh')
          }
        }
      })
    }
    const showAdditional = false

     // Tooltip content with HTML line breaks
     const tooltipContent = computed(() => {
      return `
        <div>
          ${props.item.total_minted} Minted - ${props.item.burned} Burned<br/>
          ${Math.max(props.item.max_quantity, props.item.total_minted)} Max Quantity
        </div>
      `;
    });

    const clampedPercentage = computed(() => {
      const percentage = (100 * props.item.total_minted / props.item.max_quantity).toFixed(0);
      return Math.min(percentage, 100);
    });

    return {
      hasSellOrders,
      hasBuyOrders,
      OSUrl,
      buyTrait,
      showAdditional,
      previewImage,
      sellOrder,
      buyOrder,
      zoomImage,
      rarityName,
      genderId,
      maleImage,
      femaleImage,
      rarity,
      tooltipContent,
      clampedPercentage
    };
  },
});
</script>
<style lang="scss">
.traitranks-ape-trait {
  border: 1px solid var(--border);
  padding: 10px;
  padding-top: 24px;
  padding-bottom: 28px;
  border-radius: 5px;
  box-shadow: 0 0 4px 3px var(--rarity-common);
  position: relative;
  text-align: center;
  &__category {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 14px;
    box-sizing: border-box;
    background-color: var(--gray-light);
    display: inline-block;
    border-radius: 20px;
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
  }
  &__minted-count {
    font-size: 14px;
    opacity: 0.5;
  }
  &__trading-price {
    font-size: 14px;
    margin-left: 3px;
  }
  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5px;
    &-2 {
      margin-top: 10px;
    }
  }
  &__price {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px;
    img {
      margin-right: 5px;
    }
  }
  &__additional {
    margin-top: 5px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    text-align: left;
    span {
      color: var(--primary);
      margin-left: 5px;
    }
  }
  &__rank {
    color: var(--primary);
    font-weight: bold;
    span {
      margin-left: 5px;
    }
  }
  &__buy {
    background-color: var(--primary);
    padding: 5px 10px;
    margin-top: 5px;
    width: 100%;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    box-sizing: border-box;
    display: inline-block;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }
  &__count {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    position: absolute;
    background-color: var(--gray-light);
    border: var(--border);
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 3px 5px;
    text-align: center;
    min-width: 26px;
    font-size: 16px;
    font-weight: bold;
    color: var(--primary);
    svg {
      margin-right: 2px;
    }
  }
  &__opensea {
    a {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2081e2;
      border-radius: 50%;
    }
  }
  &__rarity {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: absolute;
    height: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 14px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    &.common {
      background-color: var(--rarity-common);
    }
    &.epic {
      background-color: var(--rarity-epic);
    }
    &.legendary {
      background-color: var(--rarity-legendary);
    }
  }
  &__count {
  }
  &__name {
    font-weight: bold;
    color: var(--primary);
    padding: 0 5px;
    line-height: 1.1;

    text-align: center;
  }
  &__id {
    color: #fff;
  }
  &__images {
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      height: auto;
      width: 100%;
      border-radius: 5px;
    }
  }
  &__image {
    cursor: pointer;
    max-width: 50%;

    &-info {
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }
  }
  &.gender-both & {
    &__images {
      img {
      }
    }
    &__image {
      flex: 1;
      &:first-child {
        margin-right: 5px;
      }
    }
  }

  &.rarity-epic {
    box-shadow: 0 0 4px 0 var(--rarity-epic);
  }
  &.rarity-legendary {
    box-shadow: 0 0 4px 0 var(--rarity-legendary);
  }
}
</style>