<template>
  <div class="gallery-disclaimer">
    <img src="@/assets/img/info.png">
    <div>
      <div
        class="gallery-disclaimer-title"
      >
        Underworld rankings considers only sacrificed teens.
      </div>
      <div
        class="gallery-disclaimer-text"
      >
        Resurrected Teens will be ranked by considering both non-sacrificed and resurrected Teens.
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue"
export default defineComponent({
    setup() {
        return {}
    }
})
</script>
<style lang="scss"></style>