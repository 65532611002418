<template>
  <div
    v-click-outside="{ handler: close }"
    class="app-select gallery-sort"
    :class="{ open: isOpen }"
  >
    <div
      class="app-select-header gallery-sort-header"
      @click="toggle"
    >
      <ISort width="14" />
      <span>{{ activeItem.name }}</span>
      <IArrowDown
        width="12"
        class="app-select-arrow gallery-sort-arrow"
      />
    </div>
    <transition>
      <div
        v-if="isOpen"
        class="app-select-body gallery-sort-body"
      >
        <a
          v-for="item in items"
          :key="item.value"
          href="javascript:void(0)"
          class="app-select-item gallery-sort-item"
          @click.prevent="selectItem(item.value)"
        >{{ item.name }}</a>
      </div>
    </transition>
  </div>
</template>
<script>
import IArrowDown from "../../assets/icons/arrow-down.svg?inline";
import ISort from "../../assets/icons/sort.svg?inline";
export default {
  components: { IArrowDown, ISort },
  props: {
    value: String,
    showMeth: Boolean,
    showPrice: Boolean,
    showGodPower: Boolean
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    activeItem() {
      return this.items.find((item) => item.value === this.value) || {};
    },
    items() {
      return [
        {
          name: "Rank: Top to Bottom",
          value: "rank_asc",
        },
        {
          name: "Rank: Bottom to Top",
          value: "rank_desc",
        },
        this.showPrice && {
          name: "Price: High to Low",
          value: "price_desc",
        },
        this.showPrice && {
          name: "Price: Low to High",
          value: "price_asc",
        },
        this.showMeth && {
          name: "METH: High to Low",
          value: "meth_desc",
        },
        this.showMeth && {
          name: "METH: Low to High",
          value: "meth_asc",
        },

        {
          name: "ID: High to Low",
          value: "id_desc",
        },
        {
          name: "ID: Low to High",
          value: "id_asc",
        },
        this.showGodPower && {
          name: "God Power: High to Low",
          value: 'power_asc'
        },
        this.showGodPower && {
          name: "God Power: Low to High",
          value: 'power_desc'
        },
      ].filter(Boolean);
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    selectItem(value) {
      this.$emit("update:value", value);
      this.close();
    },
  },
};
</script>
