<template>
    <div class="traitranks-apes-traits-activity">
        <ATable :data="items" :fields="fields" v-if="!isLoading">
            <template #col-buyer="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-seller="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" v-if="data" />
                <span v-else>-</span>
            </template>
            <template #col-traits="{ data }">
                <div class="community-activity-traits">
                    <CollectionCommunityActivityTrait
                        v-for="(item, idx) in data"
                        :trait="item"
                        :key="idx"
                    />
                </div>
            </template>
        </ATable>
        <div class="traitranks-apes-traits-activity-loading" v-if="isLoading">
            <img src="@/assets/img/spinner-clear.svg" width="60" class="loader" />
        </div>
    </div>
</template>
<script setup>
import { useAnyApi } from "@/api/useAnyApi";
import { useWeb3Store } from "@/store/web3";
import moment from "moment";
import { ref } from "vue-demi";
import ATable from "@/components/ATable/ATable.vue";
import CollectionCommunityActivityTrait from "@/components/Collection/CollectionCommunityActivity/CollectionCommunityActivityTrait.vue";
import CollectionCommunityActivityWallet from "@/components/Collection/CollectionCommunityActivity/CollectionCommunityActivityWallet.vue";
const isLoading = ref(false)
const items = ref([])
const fetchData = async () => {
    isLoading.value = true
    const endpoints = ['traits-trading-logs', 'shop-logs']
    const resolvers = endpoints.map(async (endpoint, idx) => {
        const res = await useAnyApi({ toast: { error: true } }).exec({
            baseURL: "https://brrod86tu2.execute-api.us-east-1.amazonaws.com/info/",
            url: endpoint,
            params: {
                page: 1,
                wallet: useWeb3Store().collectionWallet,
                network: 'mainnet'
            }
        })
        if (idx === 0) {
            return res.list.map(item => ({
                type: "trading",
                typeName: "Marketplace",

                date: item.date,
                buyer: item.buyer,
                seller: item.seller,
                traits: [item.trait], amounts: [item.amount]
            }))
        }
        if (idx === 1) {
            return res.list.map(item => ({ ...item, type: 'Shop', buyer: item.wallet, traits: [item.trait], }))
        }

        return res.list
    })
    const result = await Promise.all(resolvers)
    let mergedRes = result.reduce((arr, item) => {
        return arr.concat(item)
    })
    mergedRes.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
    mergedRes = mergedRes.slice(0, 15)
    items.value = mergedRes.map(item => {
        const date = moment(item.date).fromNow()
        return { ...item, date }
    })
    isLoading.value = false
}

fetchData()
const fields = [
    {
        name: "Date",
        value: 'date',
    },

    // {
    //     name: "Type",
    //     value: 'typeName',
    // },
    {
        name: "Buyer",
        value: 'buyer',
    },
    {
        name: "Seller",
        value: 'seller',
    },
    {
        name: "Traits",
        value: 'traits',
    }
]
</script>
<style lang="scss">
.traitranks-apes-traits-activity {
    width: calc(99vw - 18rem);
    max-width: 100%;
    overflow: auto;
    &-loading {
        text-align: center;
    }
    @media screen and (max-width: 768px) {
        width: 99vw;
    }
}
</style>