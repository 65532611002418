import { useApesStorageApi } from "@/api/useApesStorageApi";
import { signMessage } from "@/helpers/sign-message";
import { Config } from "@/config";
import { useWeb3Store } from "@/store/web3";
import { useToast } from "@/composables/useToast";
import { captureError } from "@/helpers/captureError";
export const traitMarketplaceRemove = async ({ offerId, id, isSelling }) => {
  try {
    const message = !isSelling 
      ? "I'm canceling a listing"
      : "I'm canceling an offer"
    const signature = await signMessage(
      message
    );
    if (!signature) return;
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/trading/delete",
      method: "DELETE",
      data: {
        wallet: useWeb3Store().collectionWallet, // selling the trait
        offerId,
        signature,
        buyOrder: isSelling, // true if removing buy order (buying the trait), false if removing sell order (selling the trait)
        network: Config.network.name,
      },
    });
    useToast().success({
      title: `Trait #${id} was successfully de-listed`,
    });
    return res;
  } catch (err) {
    captureError(err);
  }
};
