<template>
  <div
    class="filter-item filter-attribute"
    :class="{ expanded: isOpen }"
  >
    <div
      class="filter-item-title filter-attribute-title"
      @click="toggleExpanded"
    >
      <div class="filter-item-header">
        <b>
          {{ filter.name }}
          <span>({{ filter.traits.length }})</span>
        </b>
      </div>
      <div class="filter-item-arrow">
        <IArrowDown />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="description filter-attribute-values"
    >
      <div
        v-if="showSearch"
        class="filter-attribute-search filter-search"
      >
        <ISearch />
        <input
          v-model="search"
          class="filter-attribute-search-input app-input"
          placeholder="Search"
        >
      </div>
      <label
        v-for="trait of filteredTraits"
        :key="trait"
        class="filter-attribute-value"
        :class="{ active: isTraitSelected(trait) }"
        @click="selectTrait(trait, !isTraitSelected(trait))"
      >
        <ACheckbox
          id="{{ trait.name }}"
          class="filter-attribute-value-checkbox"
          :active="isTraitSelected(trait)"
        />
        <!-- <input
          class="filter-attribute-value-checkbox"
          type="checkbox"
          id="{{ trait.name }}"
          v-model="trait.selected"
          @input="selectTrait(trait)"
        />-->
        <div class="filter-attribute-value-name">
          <div class="filter-attribute-value-name-inner">
            <div class>{{ trait.name }}</div>
            <div
              v-if="trait.floorPrice"
              class="filter-attribute-value-name-floor"
            >
              <span>
                <IEthereum width="7" />
                Floor {{ trait.floorPrice.toFixed(2) }}
              </span>

              <span v-if="trait.dealPrice && activeDealPrice">Next {{ trait.dealPrice.toFixed(2) }}</span>
              <span v-if="trait.dealPrice && activeDealPrice">Gap {{ trait.gap.toFixed(2) }}</span>
            </div>
          </div>
          <span class="filter-attribute-value-name-total">{{ trait.total }}</span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import ACheckbox from "../ACheckbox.vue";
import ISearch from "../../assets/icons/search.svg?inline";
import IArrowDown from "../../assets/icons/arrow-down.svg?inline";
import IEthereum from "../../assets/icons/ethereum.svg?inline"
export default {
  components: { ACheckbox, ISearch, IArrowDown, IEthereum },
  props: {
    selectedTraits: Array,
    activeDealPrice: Boolean,
    isOpen: Boolean,
    filter: {
      type: Object,
    },
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {

    filteredTraits() {
      return this.filter.traits.filter((trait) =>
        trait.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    isTraitSelected(trait) {
      return this.selectedTraits.findIndex(item => item.trait == trait.name && item.name == this.filter.name) > -1
    },
    toggleExpanded: function () {
      this.$emit("update:isOpen", !this.isOpen);
      this.search = "";
    },
    selectTrait: function (trait, active) {
      this.$emit('update:value', { name: this.filter.name, trait: trait.name, active })
    },
  },
};
</script>
