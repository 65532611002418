<template>
  <div
    v-click-outside="{ handler: close }"
    class="filter-search-select"
    :class="{ open: isOpen }"
  >
    <div
      class="app-select-header filter-search-select-header"
      @click="toggle"
    >
      <span>
        {{ activeItem.name }}
      </span>
      <IArrowDown
        width="12"
        class="app-select-arrow"
      />
    </div>
    <transition>
      <div
        v-if="isOpen"
        class="app-select-body app-select-body"
      >
        <a
          v-for="item in items"
          :key="item.value"
          href="javascript:void(0)"
          class="app-select-item app-select-item"
          @click.prevent="selectItem(item.value)"
        >
          {{ item.name }}
        </a>
      </div>
    </transition>
  </div>
</template>
<script>
import IArrowDown from "../../assets/icons/arrow-down.svg?inline";
export default {
  components: { IArrowDown },
  props: {
    value: String,
    items: Array
  },
  data() {
    return {
      isOpen: false,
    
    };
  },
  computed: {
    activeItem() {
      return this.items.find((item) => item.value === this.value) || {};
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    selectItem(value) {
      this.$emit("update:value", value);
      this.close();
    },
  },
};
</script>
