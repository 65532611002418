<template>
  <div class="gallery-disclaimer">
    <img src="@/assets/img/meth.png">
    <div>
      <div class="gallery-disclaimer-title">
        DISCLAIMER - METH TOKEN TOTAL IS UPDATED HOURLY
      </div>
      <div class="gallery-disclaimer-text">
        Confirm
        <a
          href="https://www.bullsandapesproject.com/faqs"
          target="_blank"
        >manually</a>
        on etherscan before buying bull
      </div>
      <div class="gallery-disclaimer-time">
        Last updated at {{ methUpdatedTime }}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue"
export default defineComponent({
    props: { methUpdatedTime: String },
    setup() {
        return {}
    }
})
</script>
<style lang="scss"></style>