import { Config } from "../config";
import { useBapApi } from "../api/useBapApi";
import { useAnyApi } from "../api/useAnyApi";
import { useHRApi } from "../api/useHRApi";
import { defineStore } from "pinia";
import { captureError } from "@/helpers/captureError";
import { useToast } from "@/composables/useToast";
export const useTraitRanksStore = defineStore("traitranks", {
  state: () => ({
    isLoading: true,
    tokens: [],
    // Bull / God Bull / Teen / Burned Teeen / God Ape
    type: "Bull",
    // rank, price, meth, power
    sort: "rank",
    // asc, desc
    sortOrder: "asc",
    searchWallet: "",
    searchIds: [],
    total: 10000,
    page: 1,
    perPage: 48,
    activePrices: [],
    activeMeth: [],
    activeRanks: [],
    selectedTraits: [],
    buyNowActive: false,
    maxPrice: 0,
    maxMeth: 0,
    maxRank: 0,
    traitsMap: {},
    methUpdated: null,
  }),
  getters: {
    OSCollectionUrl() {
      return "https://opensea.io/assets/ethereum/0x2cf6be9aac1c7630d5a23af88c28275c70eb8819/";
    },
    hasActiveFilters(state) {
      return (
        state.selectedTraits.length > 0 ||
        state.activePrices.length ||
        state.activeMeth.length ||
        state.activeRanks.length ||
        state.buyNowActive
      );
    },
  },
  actions: {
    async init() {
      this.isLoading = true;
      try {
        await Promise.all([
          this.loadTraitsMap(),
          this.loadStats(),
          this.fetchItems(),
        ]);
      } catch (err) {
        captureError(err);
      }

      this.isLoading = false;
    },
    async loadStats() {
      const data = await useBapApi().exec({
        type: "GET",
        url: "/collection/maxs",
        params: {
          type: this.type,
        },
      });
      const result = data.result;
      this.maxMeth = result.meth;
      this.maxPrice = result.price;
      this.maxRank = result.rank;
    },
    async fetchItems(silent = false) {
      if (!silent) {
        this.isLoading = true;
      }
      try {
        const skip = (this.page - 1) * this.perPage;
        const data = await useBapApi({
          throw: true,
        }).exec({
          url: "/collection/list",
          params: {
            type: this.type,
            sort: this.sort,
            order: this.sortOrder === "asc" ? undefined : this.sortOrder,
            limit: this.perPage,
            skip,
            owner: this.searchWallet,
            minrank: this.activeRanks[0],
            maxrank: this.activeRanks[1],
            minprice: this.buyNowActive
              ? this.activePrices[0] || 0.0001
              : this.activePrices[0],
            maxprice: this.activePrices[1],
            minmeth: this.activeMeth[0],
            maxmeth: this.activeMeth[1],
            id: this.searchIds,
            traits: this.selectedTraits,
          },
          paramsSerializer: (params) => {
            let str = "";
            Object.keys(params).forEach((key, idx) => {
              if (!params[key]) return;

              if (key === "traits") {
                if (params[key].length) {
                  str += "&";
                }
                params[key].forEach((item, index) => {
                  const trait = item.name;
                  const value = item.trait;
                  str += `trait=${trait}&value=${value}`;
                  if (index < params[key].length - 1) {
                    str += "&";
                  }
                });
                return;
              }
              if (Array.isArray(params[key])) {
                if (params[key].length) {
                  str += "&";
                }
                params[key].forEach((item, index) => {
                  str += `${key}=${item}`;
                  if (index < params[key].length - 1) {
                    str += "&";
                  }
                });
                return;
              }
              str += `&${key}=${params[key]}`;
            });

            return str.substr(1);
          },
        });
        const { success, result } = data;
        const { result: tokens } = result;
        if (!success) {
          throw new Error("Error while fetching bull", { cause: data });
        }
        this.total = result.count;
        this.tokens = tokens;
      } catch (err) {
        useToast().error({ title: "Error while loading items" });
        captureError(err);
      }
      this.isLoading = false;
    },
    async loadTraitsMap() {
      this.traitsMap = {};
      if (this.type === "Teen" || this.type === "Burned Teen") {
        const response = await useAnyApi({
          throw: true,
          toast: { error: () => "Error while loading Traits" },
        }).exec({
          method: "POST",
          url: "https://storage.mint.bullsandapesproject.com/teen-traits",
        });
        const traits = response;
        this.traitsMap = traits;
      } else {
        const response = await useHRApi({
          throw: true,
          toast: { error: () => "Error while loading Traits" },
        }).exec({
          url: "/v1/bap/traits-data",
          params: {
            collection_slug:
              this.type === "Bull" || this.type === "God Bull"
                ? "bullsandapes-genesis"
                : "bullsandapes-teenbulls",
            version: 2,
          },
        });

        const traits = response.traits;
        this.traitsMap = traits;
        this.methUpdated = new Date(response.meth.updated_at * 1000);
      }
    },
  },
});
