<template>
  <div class="gallery-active-filters-wrapper">
    <span class="gallery-active-filters-title">Filters:</span>
    <div class="gallery-active-filters">
      <div
        v-for="(tag) in items"
        :key="tag"
        class="gallery-active-filter"
      >
        {{ tag.name }}:
        <b>{{ tag.trait }}</b>
        <span
          class="gallery-active-filter__remove"
          @click="$emit('select', { name: tag.name, trait: tag.trait, active: false })"
        >
          <IClose width="16" />
        </span>
      </div>

      <div
        :key="'clear'"
        class="gallery-active-filters__clear"
        @click="$emit('clear')"
      >
        Clear All
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue"
import IClose from "@/assets/icons/close.svg?inline";
export default defineComponent({
    components: { IClose },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    setup() {
        return {}
    }
})
</script>
<style lang="scss"></style>