<template>
    <div class="traitranks-offers">
        <div class="traitranks-offers">
            <div class="traitranks-offers-title">My Orders</div>
            <!-- <TraitRanksOffer v-for="(order, idx) in buyOrders" :order="order" :key="idx" /> -->
            <div style="text-align: center;" v-if="isLoading" >
                <img class="loader" src="@/assets/img/spinner-clear.svg" width="80" />
            </div>
            <ATable class="traitranks-offers-table" :fields="fields" :data="orders" v-else>
                <template #col-price="{ data }">{{ data }} METH</template>
                <template #col-trait_id="{ index }">
                    <a href="#" class="traitranks-offers-item" @click.prevent="openItemModal(orders[index])"
                        v-if="orders[index].item">
                        <img width="40" height="40" :src="resizeImageCF(orders[index].item?.image, 148)"
                            alt="trait-image" />
                        {{ orders[index].item?.name }}
                    </a>
                    <span v-else>#{{ orders[index].trait_id }}</span>
                </template>
                <template #col-date="{ data }">{{ moment(data).format('DD/MM/YYYY') }}</template>
                <template #col-action="{ index }">
                    <a href="#" class="traitranks-offers-button"
                        @click.prevent="cancelOffer(orders[index], true)">Cancel</a>

                </template>
            </ATable>
        </div>
        <!-- <div>

            <div>Sell Orders</div>
            <ATable :fields="fields" :data="sellOrders">
                <template #col-action="{ index }">
                    <a href="#" @click.prevent="cancelOffer(buyOrders[index], false)">Cancel</a>
                </template>
            </ATable>
        </div> -->
    </div>
</template>
<script setup>
import ATable from '@/components/ATable/ATable.vue';
import TraitRanksOffer from './TraitRanksOffer.vue';
import { traitMarketplaceRemove } from '@/functions/trait-marketplace/trait-marketplace-remove';
import moment from "moment"
import { resizeImageCF } from '@/helpers/resize-image-cloudflare';
import { traitMarketplaceUpdate } from '@/functions/trait-marketplace/trait-marketplace-update';
import { useModal } from '@/composables/useModal';
import { ModalName } from '@/components/Modal/modal-name';
import { computed, ref } from 'vue';
import { useWalletCollectionStore } from '@/store/wallet-collection';
import { useWeb3Store } from '@/store/web3';
import { useApesStorageApi } from '@/api/useApesStorageApi';
import { Config } from '@/config';

const props = defineProps({

    isUtility: Boolean
})
const emit = defineEmits(['refresh'])
const fields = [
    {
        name: 'Token',
        value: 'trait_id'
    },
    {
        name: "Price",
        value: 'price'
    },
    {
        name: 'Amount',
        value: 'amount'
    },

    {
        name: 'Date',
        value: 'date'
    },

    {
        name: 'Type',
        value: 'type'
    },
    {
        name: 'Action',
        value: 'action'
    },
]
const buyOrders = ref([])
const sellOrders = ref([])
const isLoading = ref(false)
const fetchOffers = async () => {
    isLoading.value = true
    if (props.isUtility) {
        const offers = await useApesStorageApi({
            toast: { error: () => "Couldn't get wallet's sale orders" },
        }).exec({
            url: "/trading/list",
            params: {
                isUtility: true,
                buyOrder: true,
                network: Config.network.name,
            },
        })
        buyOrders.value = offers.buyOrders
        sellOrders.value = offers.sellOrders
    } else {
        const [buy, sell] = await Promise.all([
            useApesStorageApi({

                toast: { error: () => "Couldn't get wallet's sale orders" },
            }).exec({
                url: "/trading/list",
                params: {
                    isUtility: false,
                    buyOrder: true,
                    network: Config.network.name,
                },
            }),
            useApesStorageApi({

                toast: { error: () => "Couldn't get wallet's sale orders" },
            }).exec({
                url: "/trading/list",
                params: {
                    isUtility: false,
                    buyOrder: false,
                    network: Config.network.name,
                },
            }), useWalletCollectionStore().fetchApeTraits()])
        buyOrders.value = buy
        sellOrders.value = sell
    }
    isLoading.value = false
}
fetchOffers()
const orders = computed(() => {
    const allOrders = [...myOffers.value.buyOrders.map(order => ({ type: 'buy', ...order })), ...myOffers.value.sellOrders.map(order => ({ type: 'sell', ...order }))]
    allOrders.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
    return allOrders
})
const cancelOffer = async (order, isSelling) => {
    const success = await traitMarketplaceRemove({ offerId: order._id, id: order.trait_id, isSelling })
    if (success) {
        emit('refresh')
    }
}

const myOffers = computed(() => {



    let myBuyOrders = buyOrders.value.filter(order => order.wallet.toLowerCase() === useWeb3Store().walletAddress.toLowerCase())
    let mySellOrders = sellOrders.value.filter(order => order.wallet.toLowerCase() === useWeb3Store().walletAddress.toLowerCase())

    myBuyOrders = myBuyOrders.map(order => {
        let item;
        if (props.isUtility) {
            item = useWalletCollectionStore().utilities.find(item => item.id == order.trait_id)
        } else {
            item = useWalletCollectionStore().apeTraits.find(item => item.id == order.trait_id)
            if (item) {
                item.image = item.unisexPreviewImageSrc
            }
        }
        return { ...order, item }
    })
    mySellOrders = mySellOrders.map(order => {
        let item;
        if (props.isUtility) {
            item = useWalletCollectionStore().utilities.find(item => item.id == order.trait_id)
        } else {
            item = useWalletCollectionStore().apeTraits.find(item => item.id == order.trait_id)
            if (item) {
                item.image = item.unisexPreviewImageSrc
            }
        }
        return { ...order, item }
    })
    return { buyOrders: myBuyOrders, sellOrders: mySellOrders }
})
const openItemModal = item => {
    const bOrders = buyOrders.value.filter(order => order.trait_id == item.trait_id)
    const sOrders = sellOrders.value.filter(order => order.trait_id == item.trait_id)
    console.log(item)
    useModal().open({
        name: ModalName.BuyTraitModal, props: {
            sellOrders: sOrders,
            buyOrders: bOrders,
            trait: item.item,
            isUtility: props.isUtility,
            onSuccess: () => {
                emit('refresh')
            }
        }
    })
}
const updateOffer = async (order) => {
    // offerId, id, price, amount, isBuying, isUtility
    await traitMarketplaceUpdate({ offerId: order._id, id: order.trait_id, isSelling })

}
</script>
<style lang="scss">
.traitranks-offers {
    &-title {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 14px;
        font-size: 20px;
    }

    &-button {
        color: var(--primary) !important;
        text-decoration: underline !important;
    }

    &-item {
        display: flex;
        align-items: center;

        img {
            border-radius: 3px;
            margin-right: 5px;
        }
    }
}
</style>