<template>
  <div class="traitranks-bulls-teens">
    <TraitRanksFiltersBulls
      :class="{ active: !hideFilters && !isLoading }"
      :filters="categoriesData"
      :selected-traits="selectedTraits"
      :sub-type="subType"
      :total="total"
      :max-rank="maxRank"
      :max-price="maxPrice"
      :min-price="minPrice"
      :min-meth="minMeth"
      :max-meth="maxMeth"
      :active-ranks="activeRanks"
      :active-meth="activeMeth"
      :buy-now-active="buyNowActive"
      :active-prices="activePrices"
      :selected-tab="selectedTab"
      @toggle="toggleFilters"
      @buyNow="buyNowToggle"
      @rankUpdate="rankUpdate"
      @priceUpdate="priceUpdate"
      @methUpdate="methUpdate"
      @search="onSearch"
      @update:attributes="updateAttributes"
    />
    <div
      class="traitranks-page-gallery"
      :class="'gallery-layout-' + activeLayout"
    >
      <GalleryTopBar
        :value="selectedTab"
        @update:value="selectTab"
      />
      <div class="gallery-banner">
        <img
          :src="galleryBanner"
          alt="banner"
        >
      </div>

      <AToggler
        v-if="selectedTab === 'bulls'"
        :tabs="[
          { name: 'Bulls', value: 'bulls' },
          { name: 'God bulls', value: 'gods' },
        ]"
        :value="subType"
        @update:value="bullsTypeUpdate"
      />
      <AToggler
        v-if="selectedTab === 'teens'"
        :tabs="[
          { name: 'Teens', value: 'teens' },
          { name: 'Underworld', value: 'burned_teens' },
        ]"
        :value="subType"
        @update:value="teensTypeUpdate"
      />
      <div class="gallery-items-header">
        <div class="gallery-items-panel">
          <GallerySort
            v-model:value="sort"
            :show-god-power="selectedTab === 'bulls' && subType === 'gods'"
            :show-meth="selectedTab !== 'teens'"
            :show-price="subType !== 'burned_teens'"
            @update:value="sortUpdate"
          />
          <div
            v-if="buyNowActive"
            class="gallery-items-total-available"
          >
            <b>{{ total }}</b>
            {{
              total > 1 ? "bulls" : "bull"
            }}
            available
          </div>
          <GalleryLayoutSwiticher v-model:value="activeLayout" />
          <div
            class="gallery-items-panel-filters-mob"
            @click="toggleFilters"
          >
            <IFilter width="16" />Filters
          </div>
        </div>
        <TraitRanksFiltersItems
          v-if="hasActiveFilters"
          :items="selectedTraits"
          @clear="clearFilters"
          @select="updateAttributes"
        />
        <TraitRanksMethDisclaimer
          v-if="selectedTab === 'bulls'"
          :meth-updated-time="methUpdatedTime"
        />
        <TraitRanksUnderworldDisclaimer v-if="selectedTab === 'teens'" />
      </div>
      <div
        v-if="isLoading"
        class="gallery-loading-wrapper"
      >
        <img
          class="loader"
          src="@/assets/img/spinner-clear.svg"
          width="150"
        >
      </div>
      <div
        v-else
        class="gallery-tokens-wrapper"
      >
        <div class="gallery-items">
          <div
            v-for="token in collection"
            :key="token.id"
            class="gallery-item"
          >
            <GalleryTokenCard
              :token="token"
              :use-menu="false"
              :show-o-s-button="subType !== 'burned_teens'"
              :show-owner="subType !== 'burned_teens'"
              :total-items="total"
              @click="openModal(token)"
            />
          </div>
        </div>
      </div>

      <GalleryPagination
        v-model:page="page"
        v-model:pageSize="pageSize"
        :total-items="total"
        :total-filtered-items="pageSize"
        :items-name="'Bulls'"
        @update:page="updatePage"
      />
    </div>

    <transition name="t-gallery-modal">
      <GalleryModal
        v-if="showModal"
        :token="modalToken"
        :show-o-s-button="subType !== 'burned_teens'"
        @close="closeModal"
      />
    </transition>
  </div>
</template>
  
<script>
import GalleryFilters from "@/components/TraitRanks/GalleryFilters.vue";
import GalleryTokenCard from "@/components/TraitRanks/GalleryTokenCard.vue";
import GalleryModal from "@/components/TraitRanks/GalleryModal.vue";
import GalleryPagination from "@/components/TraitRanks/GalleryPagination.vue";
import GallerySort from "@/components/TraitRanks/GallerySort.vue";
import AToggler from "@/components/AToggler.vue";

import IFilter from "@/assets/icons/filter.svg?inline";
import GalleryLayoutSwiticher from "@/components/TraitRanks/GalleryLayoutSwitcher.vue";
import GalleryTopBar from "@/components/TraitRanks/GalleryTopBar.vue"
import { mapWritableState, mapState } from "pinia"
import { useTraitRanksStore } from '@/store/traitranks'
import InfiniteLoading from "v3-infinite-loading";
import TraitRanksMethDisclaimer from "@/components/TraitRanks/TraitRanksMethDisclaimer.vue";
import TraitRanksUnderworldDisclaimer from "@/components/TraitRanks/TraitRanksUnderworldDisclaimer.vue";
import TraitRanksFiltersItems from "@/components/TraitRanks/TraitRanksFiltersItems.vue";
import TraitRanksFiltersBulls from "../TraitRanksFilters/TraitRanksFiltersBulls.vue";


export default {
    props: {
        type: String
    },
    components: {
        TraitRanksFiltersBulls,
        TraitRanksFiltersItems,
        TraitRanksUnderworldDisclaimer,
        TraitRanksMethDisclaimer,
        InfiniteLoading,
        GalleryTopBar,
        IFilter,
        GalleryLayoutSwiticher,
        AToggler,
        GalleryFilters,
        GalleryTokenCard,
        GalleryModal,
        GalleryPagination,
        GallerySort,
    },
    data() {
        return {
            tokenId: [],

            hideFilters: true,
            // sort: "rank_asc",
            // selectedTab: "bulls",
            // subType: 'bulls',
            activeLayout: "big",
            showModal: false,
            modalToken: {},
        };
    },
    created() {
        const store = useTraitRanksStore()
        if (this.type) {
            this.selectTab(this.type)
        } else {
            store.init()
        }
    },
    computed: {
        selectedTab() {
            if (this.collectionType === 'Bull' || this.collectionType === 'God Bull') return 'bulls'
            return 'teens'
        },
        subType() {
            if (this.collectionType === 'Burned Teen') return 'burned_teens'
            if (this.collectionType === 'Teen') return 'teens'
            if (this.collectionType === 'Bull') return 'bulls'
            if (this.collectionType === 'God Bull') return 'gods'
            return ''

        },
        sort: {
            get() {
                return `${this.sortName}_${this.sortOrder}`
            },
            set(val) {
                const [name, order] = val.split('_')
                this.sortName = name
                this.sortOrder = order
            }
        },
        ...mapWritableState(useTraitRanksStore, [
            'activePrices',
            'activeMeth',
            'activeRanks',
            'selectedTraits',
            'buyNowActive',
            'searchWallet',
            'searchIds',
            'isLoading',

            'page'
        ]),
        ...mapWritableState(useTraitRanksStore, {
            sortName: 'sort',
            sortOrder: 'sortOrder',
            collectionType: 'type',
            pageSize: 'perPage'
        }),
        ...mapState(useTraitRanksStore, [
            'maxPrice',
            'maxMeth',
            'maxRank',
            'total',
            'tokens',
            'traitsMap',
            'methUpdated',
            'canLoadMore'
        ]),
        minMeth() {
            return 0
        },
        minPrice() {
            return 0
        },
        minRank() {
            return 1
        },

        categoriesData() {
            let traits = this.traitsMap;
            if (this.selectedTab === 'teens') {
                const traitsMapBurned = {};
                const traitsMapAvailable = {};
                Object.keys(traits).map((categoryName) => {
                    Object.keys(traits[categoryName]).map((trait) => {
                        const { available, burned } = traits[categoryName][trait];

                        if (!traitsMapAvailable[categoryName])
                            traitsMapAvailable[categoryName] = {};
                        traitsMapAvailable[categoryName][trait] = available;
                        if (!traitsMapBurned[categoryName])
                            traitsMapBurned[categoryName] = {};
                        traitsMapBurned[categoryName][trait] = burned;
                    });
                });
                if (this.subType === 'teens') {
                    traits = traitsMapAvailable
                } else {
                    traits = traitsMapBurned

                }
            }
            const filters = [];
            traits &&
                Object.keys(traits).forEach((category) => {
                    let filter = traits[category];
                    if (!filters[category]) {
                        filters[category] = {
                            name: category,
                            traits: [],
                        };
                    }
                    const selectedTraits = [];
                    Object.keys(filter).forEach((trait) => {
                        const floorPrice = filter[trait].floor_price;
                        const dealPrice = [...(filter[trait].prices || [])].sort(
                            (a, b) => a - b
                        )[1];
                        const gap = dealPrice - floorPrice;
                        selectedTraits.push({
                            name: trait,
                            creativityFactor: 1,
                            total: (filter[trait] && filter[trait].assets && filter[trait].assets.length) || 1,
                            floorPrice,
                            dealPrice,
                            gap,
                            forSaleCount: filter[trait].for_sale_count,
                            selected: false,
                        });
                    });
                    selectedTraits.sort((a, b) => {
                        return b.total - a.total;
                    });

                    filters[category].traits = selectedTraits;
                });

            filters.sort();
            return filters;
        },

        galleryBanner() {
            const banner = require("@/assets/img/banners/1.3.jpg");
            if (this.subType === "burned_teens") {
                return require("@/assets/img/banners/burned_teens.jpeg");
            }
            return banner;
        },
        methUpdatedTime() {
            const time = this.methUpdated;
            if (!time) return;
            const timezone = time
                .toLocaleDateString(undefined, {
                    day: "2-digit",
                    timeZoneName: "short",
                })
                .substring(4);
            let hours = time.getHours();
            let minutes = time.getMinutes();
            let isPm = hours >= 12;
            hours = hours % 12 || 12;

            const timeStr = `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${isPm ? "PM" : "AM"
                }`;
            return `${timeStr} ${timezone}`;
        },
        hasActiveFilters() {
            const store = useTraitRanksStore()
            return store.hasActiveFilters
        },
        collection: function () {
            return this.tokens
        },
    },
    methods: {

        updateAttributes({ name, trait, active }) {
            if (active) {
                this.selectedTraits.push({ name, trait })
            } else {
                const idx = this.selectedTraits.findIndex(item => (item.trait === trait && item.name === name))
                this.selectedTraits.splice(idx, 1)
            }
            this.page = 1
            this.refreshTokens()
        },
        clearAttributes() {
            this.selectedTraits = []
            this.page = 1

        },
        clearFilters() {
            this.clearAttributes()
            this.activePrices = []
            this.activeMeth = []
            this.activeRanks = []

            this.buyNowActive = false
            this.page = 1

            this.refreshTokens();
        },
        bullsTypeUpdate(value) {
            if (value === 'bulls') {
                this.collectionType = 'Bull'
            } else {
                this.collectionType = 'God Bull'
            }
            if (this.sort == "power_asc" || this.sort === "power_desc") {
                this.sort = "rank_asc";
            }
            this.clearFilters();
            const store = useTraitRanksStore()
            store.init()
            // this.refreshTokens();

        },
        teensTypeUpdate(value) {
            if (value === 'teens') {
                this.collectionType = 'Teen'
            } else {
                this.collectionType = 'Burned Teen'
            }
            if (this.sort == "price_asc" || this.sort === "price_desc") {
                this.sort = "rank_asc";
            }
            this.clearFilters();
            const store = useTraitRanksStore()
            store.init()
            // this.refreshTokens();

        },
        sortUpdate() {
            this.page = 1
            const store = useTraitRanksStore()
            store.fetchItems()
        },
        priceUpdate(newPrices) {
            this.page = 1

            this.activePrices = newPrices;
            if (newPrices[0] > this.minPrice || newPrices[1] < this.maxPrice) {
                this.buyNowActive = true;
            } else {
                this.buyNowActive = false;
            }
            this.refreshTokens();
        },
        methUpdate(newMeth) {
            this.page = 1

            this.activeMeth = newMeth;

            this.refreshTokens();
        },

        onSearch({ type, value }) {
            if (type === "id") {
                this.searchIds = value
                    .split(/[,\s;]/)
                    .map((item) => {
                        return parseInt(item);
                    })
                    .filter((item) => !isNaN(item));
                this.searchWallet = ''
            } else if (type === "wallet") {
                this.searchWallet = value
                this.searchIds = [];
            }
            this.page = 1

            this.refreshTokens();
        },
        async selectTab(tab) {
            // this.selectedTab = tab;
            this.$emit('update:type', tab)

            const store = useTraitRanksStore()

            if (tab === "teens") {
                this.collectionType = 'Teen'
                if (this.sort === "meth_asc" || this.sort === "meth_desc") {
                    this.sort = "rank_asc";
                }
                this.activeMeth = [];
            } else if (tab === 'bulls') {
                this.collectionType = 'Bull'
            } else {
                store.$reset()
                return
            }
            this.selectedTraits = []
            this.activePrices = []
            this.activeMeth = []
            this.activeRanks = []
            this.buyNowActive = false
            this.page = 1

            this.searchIds = []

            await store.init()
        },

        async refreshTokens() {
            const store = useTraitRanksStore()
            await store.fetchItems()
            window.scrollTo(0, 0,)
        },



        toggleFilters() {
            this.hideFilters = !this.hideFilters;
        },

        buyNowToggle(value) {

            this.buyNowActive = value;
            if (!value) {
                this.activePrices = [];
            }
            this.refreshTokens();
        },
        rankUpdate(value) {
            this.page = 1
            this.activeRanks = value;
            this.refreshTokens();
        },

        openModal(token) {
            this.modalToken = token;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.modalToken = {};
        },
        updatePage() {
            this.refreshTokens()
        },
    },

};
</script>
  