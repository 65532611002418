<template>
  <div
    class="gallery-card"
    :class="{
      'for-sale': token.forSale, burned: token.isBurned,
      'top-1': isTop1,
      'top-10': isTop10,
      'top-25': isTop25,
    }"
  >
    <div class="gallery-card-image">
      <img :src="image" :alt="token.id" width="467" height="467" />
    </div>

    <div class="gallery-card-content">
      <div class="gallery-card-info">
        <span class="gallery-card-rarity">Rank {{ token.rank }}</span>
        <!--<span>{{token.score}}</span>-->
        <span class="gallery-card-token">#{{ token.id }}</span>
        <!-- <span>{{ token.score }}</span> -->
      </div>
      <div v-if="showScore">Score {{ token.score.toFixed(2) }}</div>
      <div v-if="false" class="gallery-card-power">
        <GodBullPowerProgress :progress="token.power" :show-tooltip="false" />
      </div>

      <a v-if="owner && showOwner" class="gallery-card-owner">
        <IUser width="12" />
        <a
          :href="
            'https://opensea.io/' + (token.ownerUsername || token.ownerAddress)
          "
          target="_blank"
          @click.stop
        >{{ owner }}</a>
      </a>

      <a
        v-if="showOSButton"
        :href="openSeaLink"
        target="_blank"
        class="gallery-card-opensea"
        @click.stop
      >
        <span>{{ token.forSale ? "Buy on OpenSea" : "Go to OpenSea" }}</span>
        <IOpenSea width="25" />
      </a>
    </div>

    <div v-if="token.price" class="gallery-card-price">
      <IEthereum width="12" />
      {{ token.price }}
    </div>
  </div>
</template>
  
<script>
let flip = false;
import IOpenSea from "@/assets/icons/opensea.svg?inline";
import IEthereum from "@/assets/icons/ethereum.svg?inline";
import { shorten } from "@/helpers/utils";
import IUser from "@/assets/icons/user.svg";
import GodBullPowerProgress from "@/components/Collection/GodBullPowerProgress.vue";
import { Config } from "@/config";
// import { Config } from "../../config";
// import { getTeenImageStaging } from "../../utils";
export default {
  components: { IOpenSea, IEthereum, IUser, GodBullPowerProgress },
  props: {
    token: {
      type: Object,
    },

    showOSButton: {
      type: Boolean,
      default: true,
    },
    showOwner: {
      type: Boolean,
      default: true,
    },
    totalItems: {
      type: Number,
      default: 10000
    }
  },
  data() {
    return {

      flip,
    };
  },
  computed: {
    apeType() {
      return this.token.type
    },
    showScore() {
      return false
      return Config.mode !== 'production'
    },
    owner() {
      return (
        this.token.ownerUsername ||
        (this.token.ownerAddress && shorten(this.token.ownerAddress, 4, 4))
      );
    },
    openSeaLink() {
      return Config.OSUrl.apes + this.token.token_id
    },
    image() {
      return this.token.image;
    },
    isInTop() {
      return (this.token.rank / this.totalItems) * 100 <= 25
    },
    isTop1() {
      return (this.token.rank / this.totalItems) * 100 <= 1
    },
    isTop10() {
      return (this.token.rank / this.totalItems) * 100 <= 10
    },
    isTop25() {
      return (this.token.rank / this.totalItems) * 100 <= 25
    },


    // image() {
    //   return this.token.isBurned && Config.network.name === "sepolia"
    //     ? getTeenImageStaging(this.token.id)
    //     : this.token.image;
    // },
  },
  async created() { },
  methods: {
    toggleFlip: function () {
      this.flip = !this.flip;
    },
  },
};
</script>
  