<template>
  <div class="gallery-pagination-wrapper">
    <div class="gallery-pagination-info">
      <GalleryPaginationSelect v-model:value="pageSizeModel" />
      <div class="gallery-pagination-text">
        {{ (page - 1) * pageSize + 1 }}
        -
        {{
          endBullIndexes
        }}
        of
        {{ totalItems }}
        {{ itemsName }}
      </div>
    </div>
    <div class="gallery-pagination">
      <a
        class="gallery-pagination__arrow gallery-pagination__arrow-left"
        href="javascript:void(0)"
        :class="{ disabled: page < 2 }"
        @click.prevent="prevPage"
      >
        <IArrowLeft width="5" />
      </a>
      <div
        v-for="(item, idx) in pagination"
        :key="idx"
        class="gallery-pagination__item"
      >
        <span
          v-if="!item"
          class="gallery-pagination__empty"
        >...</span>
        <a
          v-else
          href="javscript:void(0)"
          class="gallery-pagination__page"
          :class="{ active: page === item }"
          @click.prevent="goToPage(item)"
        >{{ item }}</a>
      </div>
      <a
        class="gallery-pagination__arrow gallery-pagination__arrow-right"
        href="javascript:void(0)"
        :class="{ disabled: page >= totalPages }"
        @click="nextPage"
      >
        <IArrowRight width="5" />
      </a>
    </div>
  </div>
</template>
<script>
import IArrowLeft from "../../assets/icons/arrow-left.svg?inline";
import IArrowRight from "../../assets/icons/arrow-right.svg?inline";
import GalleryPaginationSelect from "./GalleryPaginationSelect.vue";
export default {
  components: {
    IArrowLeft, IArrowRight,
    GalleryPaginationSelect
  },
  props: {
    itemsName: String,
    page: Number,
    pageSize: Number,
    totalFilteredItems: Number,
    totalItems: Number,
  },
  emits: ["update:page", "update:pageSize"],
  data() {
    return {
      rangeSize: 1,
    };
  },
  computed: {
    endBullIndexes() {
      const res = (this.page - 1) * this.pageSize + this.pageSize
      if (res > this.totalItems) return this.totalItems
      return res
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },

    pageSizeModel: {
      get() {
        return this.pageSize;
      },
      set(val) {
        this.$emit("update:pageSize", val);
        this.goToPage(1)
      },
    },

    pagination() {
      const res = [];
      const minPaginationElems = 5 + this.rangeSize * 2;
      let rangeStart =
        this.totalPages <= minPaginationElems ? 1 : this.page - this.rangeSize;
      let rangeEnd =
        this.totalPages <= minPaginationElems
          ? this.totalPages
          : this.page + this.rangeSize;
      rangeEnd = rangeEnd > this.totalPages ? this.totalPages : rangeEnd;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;
      if (this.totalPages > minPaginationElems) {
        const isStartBoundaryReached = rangeStart - 1 < 3;
        const isEndBoundaryReached = this.totalPages - rangeEnd < 3;
        if (isStartBoundaryReached) {
          rangeEnd = minPaginationElems - 2;
          for (let i = 1; i < rangeStart; i++) {
            res.push(i);
          }
        } else {
          res.push(1);
          res.push(null);
        }
        if (isEndBoundaryReached) {
          rangeStart = this.totalPages - (minPaginationElems - 3);
          for (let i = rangeStart; i <= this.totalPages; i++) {
            res.push(i);
          }
        } else {
          for (let i = rangeStart; i <= rangeEnd; i++) {
            res.push(i);
          }
          res.push(null);
          res.push(this.totalPages);
        }
      } else {
        for (let i = rangeStart; i <= rangeEnd; i++) {
          res.push(i);
        }
      }
      return res;
    },
  },
  methods: {
    goToPage(page) {
      this.$emit("update:page", page);
    },
    goToStart() {
      this.$emit("update:page", 1);
    },
    goToEnd() {
      this.$emit("update:page", this.totalPages);
    },
    prevPage() {
      if (this.page < 2) return;
      this.$emit("update:page", this.page - 1);
    },
    nextPage() {
      if (this.page >= this.totalPages) return;
      this.$emit("update:page", this.page + 1);
    },
  },
};
</script>
