<template>
  <div class="filter-slider-wrapper filter-price">
    <input
      v-if="max"
      class="filter-price-input"
      :value="sliderModel[0]"
      type="number"
      @change="onInputChange([$event.target.value, sliderModel[1]])"
    >

    <Slider
      v-if="max"
      v-model="sliderModel"
      class="filter-slider filter-price-slider"
      :min="thisMin"
      :max="thisMax"
      :tooltips="true"
      :show-tooltip="'always'"
      :tooltip-position="'top'"
      :format="format"
      :step="useDecimal ? -1 : 1"
      :merge="thisMax / 2"
      @change="onChange"
    />
    <input
      v-if="max"
      class="filter-price-input"
      :value="sliderModel[1]"
      type="number"
      @change="onInputChange([sliderModel[0], $event.target.value])"
    >
    <IReset
      v-if="(useScale && thisMin !== min) || thisMax !== max"
      class="filter-price-reset"
      @click="resetScale"
    />
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import IReset from "../../assets/icons/reset.svg?inline";
export default {
  components: { Slider, IReset },
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    max: Number,
    min: Number,
    sufix: String,
    useDecimal: {
      type: Boolean,
      default: true,
    },
    useScale: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value"],
  data() {
    return {
      thisMin: this.min,
      thisMax: this.max,
    };
  },
  computed: {
    sliderModel: {
      get() {
        return this.value.length ? this.value : [this.min, this.max];
      },
      set(val) {
        this.onChange(val);
      },
    },
  },
  watch: {
    max() {
      this.onChange([this.min, this.max]);
      this.thisMax = this.max;
    },
    min() {
      this.thisMin = this.min;
    },
    value() {
      if (!this.useScale) return;
      if (!this.value.length) {
        this.thisMin = this.min;
        this.thisMax = this.max;
      }
    },
  },
  methods: {
    resetScale() {
      this.thisMin = this.min;
      this.thisMax = this.max;
    },
    onInputChange(value) {
      let min = +value[0];
      let max = +value[1];
      if (this.useScale) {
        if (min < this.min) {
          min = this.min;
        }
        if (max > this.max) {
          max = this.max;
        }
        if (min > max) {
          min = max;
        }
        this.thisMin = min;
        this.thisMax = max;
      }

      this.onChange([min, max]);
    },
    format(value) {
      return `${value} ${this.sufix}`;
    },
    async onChange(newValue) {
      await this.$nextTick();
      if (newValue[0] == this.min && newValue[1] == this.max) {
        this.$emit("update:value", []);
        return;
      }
      if (this.useScale) {
        this.thisMin = newValue[0];
        this.thisMax = newValue[1];
      }
      this.$emit(
        "update:value",
        newValue.map((val) => parseFloat(val))
      );
    },
  },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
