<template>
  <div class="gallery-topbar">
    <div class="gallery-topbar-tabs">
      <a href="javascript:void(0)" class="gallery-topbar-tab" :class="{ active: value === 'bulls' }"
        @click.prevent="selectTab('bulls')">Genesis Bulls</a>
      <a href="javascript:void(0)" class="gallery-topbar-tab" :class="{ active: value === 'teens' }"
        @click.prevent="selectTab('teens')">Teen Bulls</a>
      <a href="javascript:void(0)" class="gallery-topbar-tab" :class="{ active: value === 'apes_traits' }"
        @click.prevent="selectTab('apes_traits')">Ape Traits </a>
      <a href="javascript:void(0)" class="gallery-topbar-tab" :class="{ active: value === 'apes' }"
        @click.prevent="selectTab('apes')">Apes</a>
      <a href="javascript:void(0)" class="gallery-topbar-tab" :class="{ active: value === 'botb' }"
        @click.prevent="selectTab('botb')">BOTB</a>
      <a href="javascript:void(0)" class="gallery-topbar-tab" :class="{ active: value === 'utilities' }"
        @click.prevent="selectTab('utilities')">Utils<span>NEW</span></a>
    </div>
    <div class="gallery-topbar-links">
      <router-link class="gallery-topbar-mycollection" :to="{ name: 'Collection' }">
        My Collection
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  methods: {
    selectTab(value) {
      this.$emit('update:value', value)
    }
  }
}
</script>