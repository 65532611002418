<template>
  <div
    class="god-bull-power-wrapper"
    :style="{ '--progress': 100 - progress + '%' }"
  >
    <div class="god-bull-power-title">
      Power
    </div>
    <div class="god-bull-power">
      <div class="god-bull-power-active" />
    </div>
    <div
      v-if="showTooltip"
      class="god-bull-power-tooltip"
    >
      Recharge per day:
      <b>{{ rechargePerDay }}</b>
      <template v-if="hoursLeft >= 24">
        <br>Days until 100% power:
        <b>{{ daysLeft }}</b>
      </template>
      <template v-if="hoursLeft < 24">
        <br>Hours until 100% power:
        <b>{{ hoursLeft }}</b>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rechargePerDay: String,
    daysLeft: [String, Number],
    progress: {
      type: Number,
      default: 0,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    hoursLeft: [Number, String]
  },

  methods: {
    rechargeInfo() {
      return;
    },
  },
};
</script>
